import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  addressCard: {
    padding: '10px 30px 10px 15px',
    // border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    position: 'relative',
  },
}));
