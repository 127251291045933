import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { memo } from 'react';
import { BasketProductCard } from '../../../basket/components/BasketProductCard/BasketProductCard';
import { useAppSelector } from '../../context/hooks';

export const BasketProductList = memo<{
  xs?: number;
}>(({ xs = 9 }) => {
  // MARK: - Hooks

  const basket = useAppSelector((state) => state.general.basket);

  // MARK: - Render

  return (
    <Grid
      justifyContent="flex-start"
      alignItems="flex-start"
      xs={xs}
      item
      container
    >
      <Grid sx={{ mt: 2 }} container>
        {basket?.map((product, index) => (
          <BasketProductCard key={index} product={product} />
        ))}
      </Grid>
      {!basket && (
        <Grid justifyContent="center" alignItems="center" container>
          <Typography fontSize={30}>
            <Box sx={{ padding: 5 }}>
              <CircularProgress />
            </Box>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
});
