import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { foodCards } from './PaymentMethod.types';
import { useAppSelector } from '../../../common/context/hooks';

export const PaymentTypeComponent = ({ onSelect }: any) => {
  const paymentMethods = useAppSelector(
    (state) => state.general.brand?.paymentMethod,
  );

  return (
    <>
      <Grid>
        <FormControl>
          <RadioGroup onChange={({ target: { value } }) => onSelect?.(value)}>
            {paymentMethods?.gateCreditCard !== false && (
              <FormControlLabel
                value="gateCreditCard"
                control={<Radio />}
                label="KAPIDA KREDİ KARTI"
              />
            )}
            {paymentMethods?.gateCash !== false && (
              <FormControlLabel
                value="cash"
                control={<Radio />}
                label="NAKİT"
              />
            )}

            <Typography>Yemek Kartları</Typography>
            {foodCards.map(
              (item) =>
                paymentMethods?.[item.name] !== false && (
                  <FormControlLabel
                    key={item.name}
                    value={item.name}
                    control={<Radio />}
                    label={item.label}
                    sx={{ ml: 2 }}
                  />
                ),
            )}
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  );
};
