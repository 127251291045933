import { Box, Typography } from '@mui/material';
import { default as MuiIconButton } from '@mui/material/IconButton';
import { useStyles } from './IconButton.styles';
import { IconButtonProps } from './IconButton.types';

export const IconButton = ({
  children,
  active,
  colored,
  className,
  label,
  ...rest
}: IconButtonProps) => {
  const { classes, cx } = useStyles({ color: rest.color });

  return (
    <MuiIconButton
      {...rest}
      className={cx(
        {
          [classes.root]: true,
          [classes.colored]: colored,
          [classes.active]: active,
        },
        className,
      )}
    >
      {children}
      {label && (
        <>
          <Box pl={1} />
          <Typography sx={{ fontSize: 16, fontWeight: 700, color: '#5e5e5e' }}>
            {label}
          </Typography>
        </>
      )}
    </MuiIconButton>
  );
};
