import { useEffect } from 'react';
import { useAppDispatch } from '../../../common/context/hooks';
import { Brand } from '../../../common/context/models';
import { generalActions } from '../../../common/context/slice';
import { useFetch } from '../../../common/hooks/useFetchSwr';
import { RequestPathNamespace } from '../../../common/models/RequestPathNamespace';

export const BrandProvider = () => {
  const dispatch = useAppDispatch();
  const { data: brand, error } = useFetch<Brand>(RequestPathNamespace.BRAND);

  useEffect(() => {
    if (brand) {
      dispatch(generalActions.setBrand(brand));
    }
  });

  useEffect(() => {
    if (error?.status === 404) {
      // TODO:: Sayfa Bulunamadı sayfası.
      window.location.href = 'https://restorantkolay.com.tr';
    }
  }, [error]);

  return <></>;
};
