import { TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { IMaskMixin } from 'react-imask';
import { SahinTextField } from '../TextField/SahinTextField';

export type SahinMaskFieldProps = Omit<TextFieldProps, 'error'> & {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  error?: any;
  autocomplete?: string;
};

const MaskedTextField = IMaskMixin(
  ({ inputRef, defaultValue, ...otherProps }: any) => (
    <SahinTextField {...otherProps} ref={inputRef} value={defaultValue} />
  ),
);

export const SahinMaskField = forwardRef<any, any>((props, ref) => {
  const { className, error, mask = '### ### ####', ...rest } = props;
  return (
    <>
      <MaskedTextField
        {...rest}
        inputRef={ref}
        radix=" "
        error={!!error}
        mask={mask}
        definitions={{ '#': /[0-9]/ }}
        helperText={error || rest.helperText}
        color={error ? 'error' : undefined}
        className={className}
        // unmask={true}
        overwrite={false}
      />
    </>
  );
});

// const MaskedTextField = IMaskMixin(
//   ({ inputRef, defaultValue, ...otherProps }: any) => (
//     <TextField {...otherProps} inputRef={inputRef} value={defaultValue} />
//   ),
// );

// export const SahinMaskField = forwardRef<any, any>((props, ref) => {
//   const { mask = '53# ### ####', ...rest } = props;

//   return (
//     <MaskedTextField
//       {...rest}
//       inputRef={ref}
//       mask={mask}
//       definitions={{ '#': /[0-9]/ }}
//       overwrite={false}
//     />
//   );
// });
