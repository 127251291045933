import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yup } from '../../../application/init/yupInit';
import { SahinMaskField } from '../../../common/components/MaskField/SahinMaskField';
import { SahinButton } from '../../../common/components/SahinButton/SahinButton';
import { SahinTextField } from '../../../common/components/TextField/SahinTextField';
import { AddressDTO } from '../../../common/context/models';
import { SahinFetch } from '../../../common/helpers/Fetch';
import { RequestPathNamespace } from '../../../common/models/RequestPathNamespace';
import { phoneCountryFormat } from '../../../common/utils/helper';

const schema = yupResolver(
  yup.object({
    name: yup.string().min(2).max(30).required(),
    surname: yup.string().min(2).max(30).required(),
    phone: yup.string().length(12).required(),
    address: yup.string().min(10).max(120).required(),
  }),
);

type AddressFormProps = {
  address?: AddressDTO;
  open: boolean;
  onClose: () => void;
  refresh: () => void;
};

export const AddressForm = ({
  address,
  open,
  onClose,
  refresh,
}: AddressFormProps) => {
  const { register, handleSubmit } = useForm<AddressDTO>({
    resolver: schema,
    defaultValues: address,
    mode: 'onSubmit',
  });

  const onSubmit = useCallback(
    (data: AddressDTO) => {
      data.phone = phoneCountryFormat(data.phone, false)!;
      const request = new SahinFetch();
      let response;
      if (address?.id) {
        // Update
        response = request.put(RequestPathNamespace.ADDRESS, data);
      } else {
        // Add
        response = request.post(RequestPathNamespace.ADDRESS, data);
      }
      response.then(() => {
        refresh();
        onClose();
      });
    },
    [address?.id, onClose, refresh],
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Grid component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid rowSpacing={3} container>
            <Grid item container>
              <SahinTextField
                {...register('title')}
                label="Başlık"
                placeholder="Daha sonra kolay hatırlamak için bir isim ver"
                fullWidth
              />
            </Grid>
            <Grid item container>
              <SahinTextField
                {...register('name')}
                label="İsim"
                placeholder="İsminizi giriniz..."
                fullWidth
              />
            </Grid>
            <Grid item container>
              <SahinTextField
                {...register('surname')}
                label="Soyad"
                placeholder="Soyadınızı giriniz..."
                fullWidth
              />
            </Grid>
            <Grid item container>
              <SahinMaskField
                {...register('phone')}
                label="Telefon"
                placeholder="Size ulaşabileceğimiz telefon numaranız"
                fullWidth
              />
            </Grid>
            <Grid item container>
              <SahinTextField
                {...register('address')}
                label="Adres"
                placeholder="Kuryenin sizi kolay bulması için yolu tarif ediniz"
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid justifyContent="flex-end" item container>
              <Grid xs={4} item>
                <SahinButton type="submit" fullWidth>
                  Kaydet
                </SahinButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
