import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<void>()((theme) => ({
  root: {
    height: 120,
    padding: 6,
    borderRadius: 10,
    position: 'relative',
    cursor: 'pointer',
    border: `2px solid ${alpha(theme.palette.primary.main, 0.5)}`,
  },
  image: {
    objectFit: 'cover',
  },
  title: {
    padding: '0 8px',
    fontSize: '1rem !important',
    fontWeight: '600',
    textAlign: 'center',
  },
  big: {
    fontSize: '16px!important',
    fontWeight: 900,
    textTransform: 'uppercase',
  },
}));
