import {
  ContactsOutlined,
  PasswordOutlined,
  StorefrontOutlined,
  SupervisedUserCircleOutlined,
} from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AccountRoutePathNamespace } from '../../../account/models/AccountRoutePathNamespace';
import { SahinButton } from '../../../common/components/SahinButton/SahinButton';
import { createLink } from '../../../common/helpers/creeateRoute';
import { accountRoutes } from '../../../common/utils/routes';
import { useStyles } from './ProfilePage.styles';

const pages = [
  {
    title: 'Bilgilerim',
    icon: <SupervisedUserCircleOutlined />,
    href: AccountRoutePathNamespace.INFO_PAGE,
  },
  {
    title: 'Siparişlerim',
    icon: <StorefrontOutlined />,
    href: AccountRoutePathNamespace.ORDER_PAGE,
  },
  {
    title: 'Adreslerim',
    icon: <ContactsOutlined />,
    href: AccountRoutePathNamespace.ADDRESS_PAGE,
  },
  {
    title: 'Şifre Değiştir',
    icon: <PasswordOutlined />,
    href: AccountRoutePathNamespace.PASSWORD_PAGE,
  },
];

export const ProfilePage = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const goHref = (item: any) => () => {
    navigate(createLink(AccountRoutePathNamespace, item.href));
  };

  return (
    <Grid justifyContent="center" item container>
      <Grid
        xs={10}
        lg={8}
        justifyContent="center"
        spacing={{ md: 8 }}
        container
      >
        <Grid
          xs={12}
          md={3.5}
          sx={{ mt: 3, display: { xs: 'none', md: 'flex' } }}
          gap={3}
          alignContent="flex-start"
          item
          container
        >
          {pages.map((item) => (
            <Grid key={item.title} container>
              <SahinButton
                startIcon={item.icon}
                onClick={goHref(item)}
                variant="space"
                fullWidth
              >
                {item.title}
              </SahinButton>
            </Grid>
          ))}
        </Grid>
        <Grid xs={12} md={8.5} className={classes.pageContent} item container>
          {accountRoutes}
        </Grid>
      </Grid>
    </Grid>
  );
};
