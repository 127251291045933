import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import { useStyles } from './SahinAccordion.styles';

type SahinAccordionProps = {
  title: string | ReactNode;
  subTitle?: string | ReactNode;
  content: any;
  isExpanded?: boolean;
};

export const SahinAccordion = ({
  title,
  subTitle,
  content,
  isExpanded = false,
}: SahinAccordionProps) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(isExpanded);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded((prevState) => !prevState)}
      sx={{ width: '100%', margin: '0!important' }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        {typeof title === 'string' ? (
          <Typography fontSize="1.2rem" fontWeight={600}>
            {title}
          </Typography>
        ) : (
          title
        )}
        {subTitle && (
          <Typography sx={{ color: 'text.secondary' }}>{subTitle}</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails className={classes.content}>
        <Typography>{content}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};
