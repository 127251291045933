import { BasketProduct, Product } from '../context/models';

export const basketController = (
  products: Product[],
  basket: BasketProduct[],
) => {
  const productIds = products.map((item) => item.id);
  const result = basket.filter((item) => productIds.includes(item.product.id));
  if (result.length !== basket.length) {
    return result;
  }
  return false;
};
