import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { SahinAlertDialogProps } from './AlertDialog.types';

export const sahinAlertDialog = ({
  message,
  type = 'success',
  duration = 2000,
  direction = { vertical: 'bottom', horizontal: 'right' },
  isCloseButton = true,
  customActionButton,
  persist = false,
}: SahinAlertDialogProps) => {
  const action = (snackbarId: any) => (
    <>
      {customActionButton && <IconButton {...customActionButton} />}
      {isCloseButton && (
        <IconButton
          onClick={() => {
            closeSnackbar(snackbarId);
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </>
  );

  enqueueSnackbar(message, {
    autoHideDuration: duration,
    action,
    anchorOrigin: direction,
    variant: type,
    persist,
  });
};
