import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    background: theme.palette.background.paper,
    minHeight: 300,
    marginTop: 200,
    padding: '50px 0 0 0',
    color: theme.palette.grey[700],

    [theme.breakpoints.down('md')]: {
      margin: 0,
    },

    '& svg': {
      color: theme.palette.grey[700],
    },
  },
  social: {},
}));
