import useSWR from 'swr';
import { useAppSelector } from '../context/hooks';
import { RequestPathNamespace } from '../models/RequestPathNamespace';

type ErrorType = any;

type ResponseType<T> = {
  data: T | null;
  totalData: number;
  totalDataPerPage: number;
  totalPage: number;
};

export const useFetch = <ResponseDataType>(
  collectionName: RequestPathNamespace,
) => {
  const auth = useAppSelector((state) => state.general.auth);
  const { data, ...rest } = useSWR<
    ResponseType<ResponseDataType>,
    ErrorType,
    { resource: RequestPathNamespace; token: string | undefined }
  >({ resource: collectionName, token: auth?.token });

  return { ...rest, ...data };
};
