import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  logo: {
    fontSize: '2em',
    fontWeight: 500,
    textDecoration: 'none',
    color: 'currentColor',
  },
});
