import { Grid, Typography } from '@mui/material';
import { memo, useMemo, useState } from 'react';
import { useAppSelector } from '../../context/hooks';
import { BasketProduct, Product } from '../../context/models';
import { createMapping } from '../../utils/helper';
import { Loading } from '../Loading/Loading';
import { ProductCard } from '../ProductCard/ProductCard';
import { useTranslation } from 'react-i18next';

export const ProductList = memo<{
  title: string;
  products?: Product[];
  xs?: number;
}>(({ products, title, xs = 9 }) => {
  const { t } = useTranslation();
  const basket = useAppSelector((state) => state.general.basket);

  const [requestTimeControl, setRequestTimeControl] = useState(false);

  const mappingBasket = useMemo(
    () => createMapping<BasketProduct>(basket, 'product.id'),
    [basket],
  );

  const filterProducts = useMemo(() => {
    const innerProducts = products?.filter((product) => product.isActive);
    if (innerProducts?.length === 0) return undefined;
    return innerProducts;
  }, [products]);

  setTimeout(() => {
    setRequestTimeControl(true);
  }, 5 * 1000);

  return (
    <Grid spacing={2} item container>
      <Grid item container>
        <Typography variant="h2" sx={{ fontSize: '2.5rem !important' }}>
          {title}
        </Typography>
      </Grid>
      <Grid
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="flex-start"
        xs={xs}
        rowSpacing={3}
        columnSpacing={5}
        item
        container
      >
        {filterProducts?.map((product) => (
          <ProductCard
            key={product.id}
            product={product}
            basket={mappingBasket.get(product.id)}
          />
        ))}
        {!filterProducts && (
          <Loading
            finallyControl={requestTimeControl}
            finallyText={t('common.empty')}
          />
        )}
      </Grid>
    </Grid>
  );
});
