import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SahinButton } from '../../../common/components/SahinButton/SahinButton';
import { SahinTextField } from '../../../common/components/TextField/SahinTextField';
import { yup } from '../../init/yupInit';
import { SahinFetch } from '../../../common/helpers/Fetch';
import { RequestPathNamespace } from '../../../common/models/RequestPathNamespace';
import { sahinAlertDialog } from '../../../common/components/AlertDialog/AlertDialog';
import { RoutePathNamespace } from '../../../common/models/RoutePathNamespace';

export const ResetPasswordPageFormYup = yupResolver(
  yup
    .object({
      newPassword: yup.string().min(6).max(20).required(),
      passwordAgain: yup.string().min(6).max(20).required(),
    })
    .required(),
);

interface ResetPasswordPageData {
  newPassword: string;
  passwordAgain: string;
}

export const ResetPasswordPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordPageData>({
    mode: 'onBlur',
    resolver: ResetPasswordPageFormYup,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [token] = useState(searchParams.get('token') || '');

  useEffect(() => {
    if (searchParams.get('token')) navigate('', { replace: true });
  }, [navigate, searchParams]);

  const onSubmit = (data: ResetPasswordPageData) => {
    const apiData = {
      token: token,
      password: data.newPassword,
    };

    new SahinFetch()
      .post(RequestPathNamespace.RESET_PASSWORD, apiData)
      .then((res) => {
        if (res.status === 'success') {
          sahinAlertDialog({
            message: 'Güncellendi!',
          });
          navigate(`/${RoutePathNamespace.LOGIN_PAGE}`, { replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box
      display="flex"
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        width={500}
        height={200}
        spacing={2}
        container
      >
        <Grid item xs={12}>
          <Typography variant="h4" component="h4" textAlign="center">
            {t('forgotPassword.resetPassword')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SahinTextField
            {...register('newPassword')}
            error={errors.newPassword?.message}
            label={t('form.newPassword')}
            placeholder={t('placeholder.newPassword')}
            type="password"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <SahinTextField
            {...register('passwordAgain')}
            error={errors.passwordAgain?.message}
            label={t('form.passwordAgain')}
            placeholder={t('placeholder.passwordAgain')}
            type="password"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <SahinButton type="submit" fullWidth>
            {t('common.send')}
          </SahinButton>
        </Grid>
      </Grid>
    </Box>
  );
};
