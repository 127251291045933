import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { default as moment } from 'moment';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../common/context/hooks';
import { initI18n } from '../common/i18n/I18n';
import { globalRoutes } from '../common/utils/routes';
import { getTheme } from '../common/utils/theme';
import { BrandProvider } from './managers/BrandProvider/BrandProvider';
import { CategoryProvider } from './managers/CategoryProvider/CategoryProvider';
import { ProductProvider } from './managers/ProductProvider/ProductProvider';
import { SliderProvider } from './managers/SliderProvider/SliderProvider';
import { UserProvider } from './managers/UserProvider/UserProvider';

// CSS
import 'react-confirm-alert/src/react-confirm-alert.css';

// INIT
import 'moment/locale/tr';
moment.locale('tr');
initI18n();

export const App = () => {
  const auth = useAppSelector((state) => state.general.auth);
  const themeMode = useAppSelector((state) => state.general.theme);
  const theme = useMemo(() => getTheme(themeMode), [themeMode]);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const authComponents = useMemo(
    () =>
      auth && (
        <>
          <UserProvider />
        </>
      ),
    [auth],
  );

  const generalComponents = useMemo(
    () => (
      <>
        <SliderProvider />
        <BrandProvider />
        <ProductProvider />
        <CategoryProvider />
      </>
    ),
    [],
  );

  return (
    <>
      {authComponents}
      {generalComponents}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {globalRoutes}
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};
