import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  productItem: {
    marginBottom: 20,
  },
  price: {
    marginTop: 3,
  },
}));
