import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<void>()((theme) => ({
  root: {
    borderRadius: 10,
    position: 'relative',
  },
  imageContainer: {
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: 300,
    borderRadius: 10,
    objectFit: 'cover',
  },
  title: {
    fontSize: '1rem !important',
    fontWeight: '600',
  },
  price: {
    fontSize: '1.1rem !important',
    fontWeight: '500',
  },
  popularTag: {
    position: 'absolute',
    right: 10,
    top: 35,
    zIndex: 1,
    fontWeight: 600,
    fontSize: '0.8rem',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: '5px',
    padding: '2px 10px',
  },
}));
