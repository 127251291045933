import { Avatar, AvatarGroup, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SahinAccordion } from '../../../common/components/Accordion/SahinAccordion';
import { TextMessage } from '../../../common/components/TextMessage/TextMessage';
import { Order } from '../../../common/context/models';
import { getCdnImageUrl } from '../../../common/helpers/image';
import { useFetch } from '../../../common/hooks/useFetchSwr';
import { RequestPathNamespace } from '../../../common/models/RequestPathNamespace';
import { currencyFormat, dateFormat } from '../../../common/utils/helper';
import { useStyles } from './AccountOrders.styles';

export const AccountOrders = () => {
  const { data: orders } = useFetch<Order[]>(RequestPathNamespace.ORDER);

  return (
    <>
      <Grid sx={{ mt: 3 }} container>
        <Grid spacing={3} alignContent="flex-start" item container>
          {orders?.map((order) => (
            <OrderCard key={order.id} order={order} />
          ))}
          {!orders && <TextMessage message="Henüz Sipariş Vermediniz !" />}
        </Grid>
      </Grid>
    </>
  );
};

const OrderCard = ({ order }: { order: Order }) => {
  const { classes, cx } = useStyles();

  const { t } = useTranslation();

  const title = (
    <Grid
      className={cx({
        [classes.addressCard]: true,
      })}
      justifyContent="space-between"
      alignItems="center"
      item
      container
    >
      <Grid item>
        <AvatarGroup spacing="small" max={4}>
          {order.basket.map((item) => (
            <Avatar
              key={item.product.id}
              alt={item.product.name}
              src={getCdnImageUrl(item.product.images?.[0])}
            />
          ))}
        </AvatarGroup>
      </Grid>
      <Grid item>{order.basket.length} Ürün</Grid>
      <Grid item>{dateFormat(order.createdAt)}</Grid>
      <Grid item>
        <Typography fontWeight={600}>
          {t(`orderStatus.${order.status}`)}
        </Typography>
      </Grid>
      <Grid item>{currencyFormat(order.price)}</Grid>
    </Grid>
  );

  const contant = (
    <Grid rowSpacing={4} item container>
      {order.basket.map((basketItem) => (
        <Grid
          key={basketItem.product.id}
          justifyContent="space-between"
          alignItems="center"
          item
          container
        >
          <Grid xs={1} item>
            <Avatar
              key={basketItem.product.id}
              alt={basketItem.product.name}
              src={getCdnImageUrl(basketItem.product.images?.[0])}
            />
          </Grid>
          <Grid xs={4} item>
            <Grid item>{basketItem.product.name}</Grid>
            <Grid item container>
              <Typography component="small" noWrap>
                {basketItem.product.description}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={2} justifyContent="flex-end" item container>
            x{basketItem.count}
          </Grid>
          <Grid xs={3} justifyContent="flex-end" item container>
            {currencyFormat(
              basketItem.count * Number(basketItem.product.price),
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Grid item container>
      <SahinAccordion title={title} content={contant} />
    </Grid>
  );
};
