import { SupervisedUserCircleOutlined } from '@mui/icons-material';
import { Avatar, Button, Grid, Menu, MenuItem } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { AccountRoutePathNamespace } from '../../../../account/models/AccountRoutePathNamespace';
import { useAppDispatch, useAppSelector } from '../../../context/hooks';
import { generalActions } from '../../../context/slice';
import { createLink } from '../../../helpers/creeateRoute';
import { RoutePathNamespace } from '../../../models/RoutePathNamespace';
import { HeaderIconButton } from '../components/HeaderIconButton/HeaderIconButton';
import { useResponsive } from '../../../hooks/useResponsive';

export const AccountMenu = () => {
  const { isXs } = useResponsive();

  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.general.auth);
  const user = useAppSelector((state) => state.general.user);

  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(
    null,
  );

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(generalActions.clearAuthentication());
  };

  return (
    <Grid item>
      {auth && (
        <>
          <Button
            onClick={handleProfileMenuOpen}
            startIcon={<Avatar src="/default_pp.png" variant="rounded" />}
            sx={{ textTransform: 'unset', color: 'text.primary' }}
          >
            {!isXs && user?.fullName}
          </Button>
          <Menu
            open={Boolean(profileAnchorEl)}
            id="menu-appbar"
            anchorEl={profileAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClose={handleProfileMenuClose}
            keepMounted
          >
            <MenuItem
              onClick={handleProfileMenuClose}
              to={createLink(
                AccountRoutePathNamespace,
                AccountRoutePathNamespace.INFO_PAGE,
              )}
              component={Link}
            >
              Hesap Bilgilerim
            </MenuItem>
            <MenuItem
              onClick={handleProfileMenuClose}
              to={createLink(
                AccountRoutePathNamespace,
                AccountRoutePathNamespace.ORDER_PAGE,
              )}
              component={Link}
            >
              Siparişlerim
            </MenuItem>
            <MenuItem
              onClick={handleProfileMenuClose}
              to={createLink(
                AccountRoutePathNamespace,
                AccountRoutePathNamespace.ADDRESS_PAGE,
              )}
              component={Link}
            >
              Adreslerim
            </MenuItem>
            <MenuItem
              onClick={handleProfileMenuClose}
              to={createLink(
                AccountRoutePathNamespace,
                AccountRoutePathNamespace.PASSWORD_PAGE,
              )}
              component={Link}
            >
              Şifre Değiştir
            </MenuItem>
            <MenuItem onClick={handleLogout} sx={{ opacity: 0.9, mt: 1 }}>
              Çıkış Yap
            </MenuItem>
          </Menu>
        </>
      )}
      {!auth && (
        <HeaderIconButton
          LinkComponent={Link}
          to={RoutePathNamespace.LOGIN_PAGE}
        >
          <SupervisedUserCircleOutlined />
        </HeaderIconButton>
      )}
    </Grid>
  );
};
