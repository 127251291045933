// const createRow = (image: string, name: string, iban: string) => ({
//   image,
//   name,
//   iban,
// });

// export const havaleBilgileri = [
//   createRow(
//     'https://cdn.ilmio.com/docs/images/banks/46.png',
//     'AKBANK',
//     'TR85 0006 7010 0000 0066 7913 13',
//   ),
//   createRow(
//     'https://cdn.ilmio.com/docs/images/banks/62.png',
//     'Garanti BBVA',
//     'TR85 0006 7010 0000 0066 7913 13',
//   ),
//   createRow(
//     'https://cdn.ilmio.com/docs/images/banks/67.png',
//     'YAPI KREDI',
//     'TR85 0006 7010 0000 0066 7913 13',
//   ),
//   createRow(
//     'https://cdn.ilmio.com/docs/images/banks/64.png',
//     'İŞ BANKASI',
//     'TR85 0006 7010 0000 0066 7913 13',
//   ),
// ];

export const foodCards = [
  {
    name: 'ticketCard',
    label: 'Ticket Restaurant Kartı',
  },
  {
    name: 'setcard',
    label: 'Setcard',
  },
  {
    name: 'multinetCard',
    label: 'Multinet Kart',
  },
  {
    name: 'payeCard',
    label: 'Paye Kart',
  },
  {
    name: 'sodexoCard',
    label: 'Sodexo Card',
  },
  {
    name: 'metropolCard',
    label: 'Metropol Card',
  },
];
