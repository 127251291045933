import { CallOutlined, WhatsApp } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../context/hooks';
import { phoneCountryFormat, phoneTurkeyFormat } from '../../../utils/helper';
import { IconButton } from '../../IconButton/IconButton';
import { HeaderIconButton } from '../components/HeaderIconButton/HeaderIconButton';
import { useResponsive } from '../../../hooks/useResponsive';

export const HeaderPhoneButton = () => {
  const { isXs } = useResponsive();
  const brand = useAppSelector((state) => state.general.brand);

  const phoneLabel = phoneTurkeyFormat(brand?.contact?.phone);
  const phone = phoneCountryFormat(brand?.contact?.phone);
  const whatsapp = phoneCountryFormat(brand?.contact?.whatsapp);

  return (
    <>
      {phone && (
        <Grid item>
          <HeaderIconButton
            component={Link}
            to={`tel:${phone}`}
            target="_blank"
            label={isXs ? 'ARA' : phoneLabel}
          >
            <CallOutlined />
          </HeaderIconButton>
        </Grid>
      )}
      {whatsapp && (
        <Grid item>
          <IconButton
            component={Link}
            to={`https://wa.me/${whatsapp}`}
            target="_blank"
            size="large"
            label="Canlı Sipariş"
            color="success"
            colored
          >
            <WhatsApp />
          </IconButton>
        </Grid>
      )}
    </>
  );
};
