import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme, params, classes) => ({
  root: {
    paddingLeft: 80,
    height: '100vh',
    position: 'relative',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down('lg')]: {
      height: '100%',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 15,
    top: 10,
  },
  drawerIcon: {
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '300px',
      height: '150vh',
      left: 20,
      top: '-25vh',
      background: 'white',
      borderRadius: '100%',
      zIndex: 1,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '3px',
      height: '100px',
      left: 40,
      top: '50%',
      transform: 'translateY(-50%)',
      background: '#ccc',
      borderRadius: 5,
      zIndex: 1,
    },
  },
  container: {
    height: '100vh',
    padding: 20,
    borderRadius: 5,
    fontSize: '.9em',
    background: 'white',
    zIndex: 3,
    [theme.breakpoints.down('lg')]: {
      height: '100%',
    },
  },
  title: {
    fontSize: '2em !important',
    padding: 10,
    textAlign: 'center',
    marginBottom: 20,
  },
  totalPrice: {
    fontWeight: 500,
    borderTop: `1px solid ${theme.palette.primary.dark}`,
    padding: theme.spacing(2, 0, 3, 0),
    margin: theme.spacing(0, 0, 0, 0),
  },
  summary: {
    overflow: 'hidden',
    flex: 1,
  },
  summaryProductList: {
    overflow: 'auto',
    flex: 1,
  },
}));
