import { Grid, TextField, Typography } from '@mui/material';
import { forwardRef } from 'react';
import { useStyles } from './SahinTextField.styles';
import { SahinTextFieldProps } from './SahinTextField.types';

export const SahinTextField = forwardRef<any, SahinTextFieldProps>(
  ({ error, label, className, ...rest }, ref) => {
    const { classes, cx } = useStyles();

    return (
      <Grid item container>
        {label && (
          <Typography className={classes.title} fontWeight={600}>
            {label}
          </Typography>
        )}
        <TextField
          {...rest}
          inputRef={ref}
          error={!!error}
          color={error ? 'error' : undefined}
          helperText={rest.helperText}
          className={cx(classes.root, className)}
        />
        <Typography className={classes.error}>{error}</Typography>
      </Grid>
    );
  },
);
