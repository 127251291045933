import { Grid, Typography } from '@mui/material';
import { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../common/context/hooks';
import { Menu } from '../../../common/context/models';
import { generalActions } from '../../../common/context/slice';
import { SahinFetch } from '../../../common/helpers/Fetch';
import { getCdnImageUrl } from '../../../common/helpers/image';
import { useEffectFirst } from '../../../common/hooks/useEffectFirst';
import { RequestPathNamespace } from '../../../common/models/RequestPathNamespace';
import { useStyles } from './MenuPage.styles';

const MenuItem = ({
  data,
  classes,
  reverse,
}: {
  data: Menu;
  classes: any;
  reverse?: boolean;
}) => {
  return (
    <Grid
      xs={12}
      className={classes.menuItem}
      justifyContent="space-between"
      direction={reverse ? 'row' : 'row-reverse'}
      item
      container
    >
      <Grid xs={4} alignItems="center" item container>
        {data?.image && (
          <img
            src={getCdnImageUrl(data?.image)}
            width="100%"
            alt={data?.name}
          />
        )}
      </Grid>
      <Grid xs={6} item>
        <Typography className={classes.menuTitle}>{data?.name}</Typography>
        <Grid rowSpacing={2} container>
          {data?.menu.map((item, index) => (
            <Grid key={index} item justifyContent="space-between" container>
              <Grid item>
                <Typography>{item.name}</Typography>
                <Typography>
                  <small>{item.description}</small>
                </Typography>
              </Grid>
              <Grid item>
                <Typography>{item.price} ₺</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export const MenuPage = memo(() => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const menu = useAppSelector((state) => state.general.menu);

  const fetchData = useCallback(() => {
    new SahinFetch()
      .get(RequestPathNamespace.MENU)
      .then(({ data }: { data: Menu[] | undefined }) => {
        if (data) dispatch(generalActions.setMenu(data));
      });
  }, [dispatch]);

  useEffectFirst(() => {
    fetchData();
  });

  return (
    <Grid xs={12} item container className={classes.root}>
      <header className={classes.header}>
        <img src="/menu-header.jpg" alt="MENÜ" />
        <Typography className={classes.title}>MENÜ</Typography>
      </header>
      {/* <Typography>{menu?.name}</Typography> */}
      <Typography className={classes.home} to="/" component={Link}>
        ONLINE SİPARİŞ
      </Typography>
      <Grid
        style={{ paddingTop: 100, paddingBottom: 200 }}
        justifyContent="center"
        container
      >
        <Grid rowSpacing={5} container xs={8} justifyContent="center">
          {menu?.map((item, index) => (
            <MenuItem
              key={index}
              data={item}
              classes={classes}
              reverse={index % 2 === 1}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
});
