import { memo } from 'react';
import { HeaderBasketIcon } from '../../HeaderBasketIcon/HeaderBasketIcon';
import { AccountMenu } from './AccountMenu';
import { HeaderPhoneButton } from './HeaderPhoneButton';

export const PopoverMenu = memo(() => {
  // MARK: - Render

  return (
    <>
      <HeaderPhoneButton />
      <HeaderBasketIcon />
      {/* <ThemeMenu /> */}
      {/* <LanguageMenu /> */}
      <AccountMenu />
    </>
  );
});
