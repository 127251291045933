import { Grid } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CheckoutSummary } from '../../../basket/components/CheckoutSummary/CheckoutSummary';
import { BasketProductList } from '../../../common/components/BasketProductList/BasketProductList';
import { SahinButton } from '../../../common/components/SahinButton/SahinButton';
import { TextMessage } from '../../../common/components/TextMessage/TextMessage';
import { useAppSelector } from '../../../common/context/hooks';

export const BasketPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const basket = useAppSelector((state) => state.general.basket);

  const goPayment = useCallback(() => {
    navigate('/payment');
  }, [navigate]);

  return (
    <Grid xs={10} item container>
      {basket.length <= 0 && (
        <>
          <Grid
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            container
          >
            <img src="/no-product.png" height={200} alt="empty-basket" />
            <TextMessage message={t('basket.emptyBasket')} />
          </Grid>
        </>
      )}
      {basket.length > 0 && (
        <Grid justifyContent="space-between" container>
          <BasketProductList xs={7} />
          <CheckoutSummary
            xs={5}
            basket={basket}
            bottomToolbar={() => (
              <SahinButton onClick={goPayment} fullWidth>
                {t('common.approve')}
              </SahinButton>
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};
