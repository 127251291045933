import { Delete, Edit } from '@mui/icons-material';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { sahinConfirmDialog } from '../../../common/components/ConfirmDialog/ConfirmDialog';
import { IconButton } from '../../../common/components/IconButton/IconButton';
import { AddressDTO } from '../../../common/context/models';
import { useStyles } from './AddressCard.styles';

type AddressCardProps = {
  address: AddressDTO;
  selected?: AddressDTO;
  onEdit: (address: AddressDTO) => void;
  onDelete: (address: AddressDTO) => void;
  onSelect: (address: AddressDTO) => void;
};

export const AddressCard = ({
  address,
  selected,
  onEdit,
  onDelete,
  onSelect,
}: AddressCardProps) => {
  const { classes, cx } = useStyles();

  const onSelectHandler = () => {
    onSelect?.(address);
  };

  const onDeleteHandler = (address: AddressDTO) => {
    sahinConfirmDialog({
      title: 'Silmek istediğ,ine emin misin?',
      message: 'bu işlem geri alınamaz!',
      onConfirm: () => onDelete?.(address),
    });
  };

  return (
    <Grid xs={12} lg={6} item container>
      <Grid
        className={cx({
          [classes.addressCard]: true,
          [classes.selecting]: Boolean(onSelect),
          [classes.addressCardActive]: selected?.id === address.id,
        })}
        component={Paper}
        onClick={onSelectHandler}
        flexDirection="column"
        justifyContent="space-between"
        item
        container
      >
        <Grid flexDirection="column" item container>
          <Typography>
            <Box fontWeight="fontWeightBold" mb={1}>
              {address.title}
            </Box>
          </Typography>
          <Typography variant="body2">{address.name}</Typography>
          <Typography variant="body2">{address.surname}</Typography>
          <Typography variant="body1">{address.address}</Typography>
          <Typography variant="body2">{address.phone}</Typography>
        </Grid>
        <Grid
          spacing={1}
          className={classes.actionButtons}
          justifyContent="flex-end"
          container
        >
          <Grid item>
            <IconButton onClick={() => onEdit(address)} size="small" colored>
              <Edit />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => onDeleteHandler(address)}
              size="small"
              colored
            >
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
