export enum RequestPathNamespace {
  BRAND = 'brand',
  USER = 'user',
  PRODUCT = 'product',
  CATEGORY = 'category',
  LOGIN = 'login',
  REGISTER = 'register',
  MENU = 'menu',
  SLIDER = 'slider',
  CONTACT = 'contact',
  ORDER = 'order',
  ADDRESS = 'address',
  BLOG = 'blogs',
  PROJECT = 'projects',
  REFERENCE = 'references',
  FORGOT_PASSWORD = 'forgotPassword',
  RESET_PASSWORD = 'resetPassword',
  UPDATE_PASSWORD = 'password',
}
