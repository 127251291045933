import { AppBar, Box, Grid, Toolbar } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import Head from '../Head/Head';
import { Logo } from '../Logo/Logo';
import { useStyles } from './Header.styles';
import { PopoverMenu } from './PopoverMenu/PopoverMenu';
import { useResponsive } from '../../hooks/useResponsive';

export const Header = memo(() => {
  const { isXs } = useResponsive();
  const { classes, cx } = useStyles();
  const [fixedHeader, setFixedHeader] = useState(false);

  const onScroll = () => {
    const body = document.body.getBoundingClientRect();
    setFixedHeader((prevState) => {
      if (body.top <= -140 && !prevState) {
        return true;
      }
      if (body.top > -40 && prevState) {
        return false;
      }
      return prevState;
    });
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll, true);
  }, []);

  return (
    <>
      <Head />
      <Box sx={{ p: { xs: 2, md: 7 } }} />
      <AppBar
        position={!isXs ? 'fixed' : undefined}
        className={cx({
          [classes.root]: true,
          [classes.fixed]: !isXs && fixedHeader,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <Grid justifyContent="center" container>
            <Grid
              justifyContent="center"
              alignItems="center"
              xs={12}
              lg={10}
              item
              container
            >
              <Logo />
              {/* <Grid xs={4}  item container>
                <Search />
              </Grid> */}
              <Grid
                xs={7}
                lg={9}
                spacing={isXs ? 0.5 : 2}
                justifyContent="flex-end"
                item
                container
              >
                <PopoverMenu />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
});
