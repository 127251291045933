import { IconButton } from '../../../IconButton/IconButton';
import { IconButtonProps } from '../../../IconButton/IconButton.types';
import { Loading } from '../../../Loading/Loading';
import { useStyles } from './HeaderIconButton.styles';

export const HeaderIconButton = ({
  children,
  className,
  loading,
  size = 'large',
  bigged,
  ...rest
}: IconButtonProps) => {
  const { cx, classes } = useStyles();

  return (
    <IconButton
      color="inherit"
      size={size}
      {...rest}
      className={cx(
        { [classes.button]: true, [classes.bigged]: bigged },
        className,
      )}
    >
      {loading && <Loading size={25} />}
      {!loading && children}
    </IconButton>
  );
};
