import { Button } from '@mui/material';
import { Loading } from '../Loading/Loading';
import { useStyles } from './SahinButton.styles';
import { SahinButtonProps } from './SahinButton.types';

export const SahinButton = ({
  children,
  leftIcon,
  rightIcon,
  variant = 'normal',
  soft,
  strong = true,
  loading,
  ...rest
}: SahinButtonProps) => {
  const { classes, cx } = useStyles();

  const rootClass = cx({
    [classes.root]: true,
    [classes.normal]: variant === 'normal',
    [classes.big]: variant === 'big',
    [classes.small]: variant === 'small',
    [classes.space]: variant === 'space',
    [classes.soft]: soft,
    [classes.strong]: strong,
  });

  return (
    <Button variant="contained" className={rootClass} {...rest}>
      {loading && <Loading size={25} />}
      {!loading && (
        <>
          {leftIcon && <span className={classes.leftIcon}>{leftIcon}</span>}
          {children}
          {rightIcon && <span className={classes.rightIcon}>{rightIcon}</span>}
        </>
      )}
    </Button>
  );
};
