export enum RoutePathNamespace {
  PREFIX = '',
  ALL_ROUTES = '*',
  HOME_PAGE = '/',
  CHECKOUT_PAGE = 'checkout',
  PAYMENT_PAGE = 'payment',
  CONTACT_PAGE = 'contact',
  ACCOUNT_PAGE = 'account',
  LOGIN_PAGE = 'login',
  REGISTER_PAGE = 'register',
  PRODUCT_PAGE = 'product',
  MENU_PAGE = 'menu',
  RESET_PASSWORD_PAGE = 'reset-password',
}
