import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../context/hooks';
import { generalActions } from '../../context/slice';
import { getCdnImageUrl } from '../../helpers/image';
import { useStyles } from './ProductCard.styles';
import { ProductCardProps } from './ProductCard.types';

export const ProductCard = ({ basket, product }: ProductCardProps) => {
  // MARK: - Hooks

  const { name, images, price, isPopular } = product;
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const [count, setCount] = useState(basket?.count || 0);

  useEffect(() => {
    setCount(basket?.count || 0);
  }, [basket?.count]);

  const handleAddBasket = useCallback(() => {
    setCount(count + 1);
    dispatch(generalActions.updateBasketCount({ product, count: 1 }));
  }, [count, dispatch, product]);

  const handleRemoveBasket = useCallback(() => {
    if (count === 0) return;
    setCount(count - 1);
    if (count === 1) {
      dispatch(generalActions.removeBasket({ product, count }));
      return;
    }
    dispatch(generalActions.updateBasketCount({ product, count: -1 }));
  }, [count, dispatch, product]);

  return (
    <Grid xs={12} md={4} lg={3} className={classes.root} item container>
      {!!isPopular && (
        <Typography fontSize="large" className={classes.popularTag}>
          Popüler
        </Typography>
      )}
      <Grid xs={12} className={classes.imageContainer} item>
        <img
          className={classes.image}
          src={getCdnImageUrl(images[0])}
          alt={name}
        />
      </Grid>
      <Grid xs={12} alignContent="space-between" item container>
        <Grid alignItems="stretch" container>
          <Typography variant="h3" className={classes.title}>
            {name}
          </Typography>
          <Grid
            justifyContent="space-between"
            alignItems="center"
            gap={6}
            wrap="nowrap"
            container
          >
            <Grid xs={6} item>
              <Typography variant="h4" className={classes.price}>
                <p>{price} ₺</p>
              </Typography>
            </Grid>
            <Grid xs={6} item>
              <ButtonGroup size="small" color="secondary">
                <Button aria-label="reduce" onClick={handleRemoveBasket}>
                  <RemoveIcon fontSize="small" />
                </Button>
                <Button aria-label="increase">{count}</Button>
                <Button aria-label="increase" onClick={handleAddBasket}>
                  <AddIcon fontSize="small" />
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
