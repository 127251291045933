export const tr = {
  common: {
    approve: 'ONAYLA',
    cancel: 'İPTAL',
    yes: 'EVET',
    no: 'HAYIR',
    other: 'Diğer',
    send: 'GÖNDER',
    empty: 'Boş',
  },
  header: {
    menu: {
      home: 'ANASAYFA',
      menu: 'MENÜ',
      login: 'GİRİŞ',
      register: 'KAYIT',
      project: 'PROJELER',
      store: 'MAĞAZA',
      library: 'KÜTÜPHANE',
      references: 'REFERANSLAR',
    },
    goToBasket: 'SEPETE GİT',
  },
  login: {
    title: 'GİRİŞ',
    submitButton: 'GİRİŞ YAP',
    withSahId: 'SAHİD İLE GİRİŞ YAP',
  },
  register: {
    title: 'KAYIT OL',
    submitButton: 'TAMAMLA',
  },
  contact: {
    submitButton: 'GÖNDER',
  },
  form: {
    fullName: 'Ad Soyad',
    firstName: 'Ad',
    lastName: 'Soyad',
    mail: 'E-Posta',
    password: 'Şifre',
    passwordAgain: 'Şifre (tekrar)',
    newPassword: 'Yeni Şifre',
    company: 'Firma',
    phone: 'Telefon',
    content: 'Açıklama',
    language: 'Dil Seçenekleri',
    suggestion: 'Beğendikleriniz',
    country: 'Ülke',
    city: 'Şehir',
    county: 'İlçe',
    name: 'Ad',
    surname: 'Soyad',
    address: 'Adres',
  },
  placeholder: {
    firstName: 'Adınızı Girin',
    lastName: 'Soyadınızı Girin',
    mail: 'E-Posta Adresinizi Girin',
    password: 'Şifrenizi Girin',
    passwordAgain: 'Şifrenizi Girin (tekrar)',
    newPassword: 'Yeni Şifrenizi Girin',
    company: 'Firma Adinizi Girin (boş bırakılabilir)',
    name: 'Adınız',
    phone: 'Telefon',
    content: 'İstediğiniz projeyi biraz açıklar mısınız?',
    language: `Türkçe,İngilizce`,
    suggestion: 'Örnek alabileceğimiz siteler',
  },
  label: {
    yesHaveManagementPanel: 'Evet, yönetim paneli istiyorum',
    noNotHaveManagementPanel: 'Hayır, yönetim paneli istemiyorum',
    corporateSite: 'Kurumsal Site',
    eCommerceSite: 'E-Ticaret Sitesi',
    newsPortalSite: 'Haber Portalı Sitesi',
    electionCandidateSite: 'Seçim Aday Sitesi',
  },
  errorMessages: {
    passwordEqual: 'Şifreler eşit olmalı',
    notFoundUser: 'Kullanıcı bulunamadı',
    notFoundGame: 'Oyun Bulunamadı',
    notFoundBlog: 'Blog Bulunamadı',
    registerError: 'Kayıt yapılırken hata oluştu',
    recaptchaError: 'Lütfen robot olmadığınızı doğrulayın',
  },
  language: {
    turkish: 'TÜRKÇE',
    english: 'ENGLISH',
  },
  paymentTypes: {
    HAVALE: 'Havale',
    ONLINE_POS: 'Online Pos',
    GATE_POS: 'Kapida Kredi̇ Karti',
    CASH: 'Naki̇t',
    OTHER: 'Di̇ğer',
  },
  productCard: {
    promotion: 'TANITIM',
    addToBasket: 'SEPETE EKLE',
    removeToBasket: 'SEPETTEN ÇIKAR',
  },
  footer: {
    infoText1: 'Soru ve Önerileriniz İçin',
    infoText2: 'Bizimle iletişime geçebilirsiniz',
    securePayment: 'Güvenli Ödeme',
  },
  basket: {
    emptyBasket: 'Sepetiniz Boş',
    minPay: 'Minimum Ödeme Tutarı {{price}} TL',
  },
  orderStatus: {
    pending: 'Siparişiniz Alındı',
    preparing: 'Hazırlanıyor',
    on_way: 'Yola çıktı',
    delivered: 'Teslim Edildi',
    rejected: 'İptal Edildi',
  },
  forgotPassword: {
    title: 'Şifremi Unuttum',
    success: 'Şifreniz e-posta adresinize gönderildi',
    error: 'E-posta gönderilirken hata oluştu',
    resetPassword: 'Şifre Sıfırla',
  },
};
