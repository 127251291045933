import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2, 2, 2, 0),
    position: 'relative',
    borderBottom: '1px solid rgba(0, 0, 0, .08)',
  },
  imageContainer: {
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
  },
  image: {
    width: 90,
    borderRadius: 10,
  },
  title: {
    fontSize: '1rem !important',
    fontWeight: '600 !important',
  },
  price: {
    fontSize: '1.1rem !important',
  },
  priceTotal: {
    fontSize: '1.1rem !important',
    fontWeight: '600 !important',
  },
  countField: {
    width: 50,
    padding: 0,
    textAlign: 'center',
    marginRight: 10,
    '& input': {
      padding: '5px 5px',
    },
    '&:focus': {
      width: 50,
    },
    [`& input[type=number]::-webkit-inner-spin-button,
      & input[type=number]::-webkit-outer-spin-button`]: {
      opacity: 1,
    },
  },
}));
