import axios from 'axios';
import { CacheNames } from '../context/slice';
import { ISahinFetch, MethodType } from './Fetch.types';
import { getCache } from '../utils/helper';

const { REACT_APP_API_URL } = process.env;

axios.interceptors.response.use(undefined, (response) => {
  if (response.response?.status === 401) {
    localStorage.removeItem(CacheNames.AUTH);
    document.location.reload();
  }
});

export class SahinFetch implements ISahinFetch {
  private baseUrl: string = `${REACT_APP_API_URL}/`!;
  private method: MethodType = MethodType.POST;

  constructor() {
    const auth = getCache(CacheNames.AUTH, null);
    axios.defaults.baseURL = this.baseUrl;
    axios.defaults.withCredentials = false;
    auth &&
      (axios.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`);
  }

  public setBaseUrl = (baseUrl: string | undefined) => {
    axios.defaults.baseURL = baseUrl;
    return this;
  };

  public get = async (route: string) => {
    return axios.get(route).then((res) => res?.data);
  };

  public post = async (route: string, data: any) => {
    return axios.post(route, data).then((res) => res?.data);
  };

  public put = async (route: string, data: any) => {
    return axios.put(route, data).then((res) => res?.data);
  };

  public delete = async (route: string, id: any) => {
    return axios.delete(`${route}/${id}`).then((res) => res?.data);
  };
}
