import { DeleteSweep } from '@mui/icons-material';
import { Grid, TextField, Typography } from '@mui/material';
import { useCallback } from 'react';
import { IconButton } from '../../../common/components/IconButton/IconButton';
import { useAppDispatch } from '../../../common/context/hooks';
import { generalActions } from '../../../common/context/slice';
import { currencyFormat } from '../../../common/utils/helper';
import { useStyles } from './BasketProductCard.styles';
import { BasketProductCardProps } from './BasketProductCard.types';
import { getCdnImageUrl } from '../../../common/helpers/image';

export const BasketProductCard = ({
  product: basketItem,
}: BasketProductCardProps) => {
  // MARK: - Hooks

  const { name, images, price } = basketItem.product;
  const dispatch = useAppDispatch();
  const { classes } = useStyles();

  // MARK: - Handlers

  const removeBasket = useCallback(() => {
    dispatch(generalActions.removeBasket(basketItem));
  }, [dispatch, basketItem]);

  const onChangeCount = useCallback(
    (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const count = Number(event.target.value);
      if (count === 0) return false;
      dispatch(generalActions.changeBasketCount({ id, count }));
    },
    [dispatch],
  );

  // MARK: - Render

  return (
    <Grid justifyContent="flex-end" item container>
      <Grid
        xs={10}
        className={classes.root}
        justifyContent="space-between"
        columnSpacing={3}
        item
        container
      >
        <Grid
          justifyContent="center"
          alignItems="center"
          className={classes.imageContainer}
          xs={2}
          item
          container
        >
          <img
            className={classes.image}
            src={getCdnImageUrl(images[0])}
            alt={name}
          />
        </Grid>
        <Grid
          xs={7}
          sx={{ p: 0.5 }}
          alignContent="space-between"
          item
          container
        >
          <Grid container>
            <Typography variant="h2" className={classes.title}>
              {name}
            </Typography>
          </Grid>
          <Grid
            justifyContent="flex-start"
            alignItems="flex-end"
            alignContent="flex-end"
            container
          >
            <Grid
              xs={6}
              justifyContent="flex-start"
              alignItems="flex-end"
              alignContent="flex-end"
              item
              container
            >
              <Typography variant="h4" className={classes.price}>
                {currencyFormat(Number(price))}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          xs={3}
          justifyContent="space-between"
          alignContent="flex-end"
          flexDirection="column"
          container
        >
          <Grid justifyContent="center" container>
            <Grid justifyContent="center" container>
              <Typography component="small" fontWeight={700} fontSize={10}>
                TOPLAM
              </Typography>
            </Grid>
            <Typography variant="h4" className={classes.priceTotal}>
              {currencyFormat(Number(price) * basketItem.count)}
            </Typography>
          </Grid>
          <Grid container>
            <Grid
              xs={8}
              justifyContent="flex-end"
              alignItems="center"
              alignContent="flex-end"
              item
              container
            >
              <TextField
                type="number"
                value={basketItem.count}
                className={classes.countField}
                onChange={onChangeCount(basketItem.product.id)}
                size="small"
              />
              adet
            </Grid>
            <Grid
              xs={4}
              justifyContent="flex-end"
              alignItems="center"
              height={37}
              item
              container
            >
              <IconButton onClick={removeBasket} colored>
                <DeleteSweep />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
