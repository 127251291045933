import { AddBoxOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useCallback, useState } from 'react';
import { HeaderIconButton } from '../../../common/components/Header/components/HeaderIconButton/HeaderIconButton';
import { AddressDTO } from '../../../common/context/models';
import { SahinFetch } from '../../../common/helpers/Fetch';
import { useFetch } from '../../../common/hooks/useFetchSwr';
import { RequestPathNamespace } from '../../../common/models/RequestPathNamespace';
import { AddressCard } from './AddressCard';
import { AddressForm } from './AddressForm';

type DialogState = undefined | null | AddressDTO;

export const AccountAddresses = ({ onSelect, selected }: any) => {
  const { data: addresses, mutate } = useFetch<AddressDTO[]>(
    RequestPathNamespace.ADDRESS,
  );
  const [formDialogOpened, setDialogOpen] = useState<DialogState>();

  const formOpen = useCallback(
    (state: DialogState) => () => {
      setDialogOpen(state);
    },
    [],
  );

  const editHandler = useCallback((address: AddressDTO) => {
    setDialogOpen(address);
  }, []);

  const deleteHandler = useCallback(
    (address: AddressDTO) => {
      new SahinFetch()
        .delete(RequestPathNamespace.ADDRESS, address.id)
        .then(() => {
          mutate();
        });
    },
    [mutate],
  );

  return (
    <>
      <Grid spacing={3} mt={{ xs: 1, md: 0 }} container>
        <Grid justifyContent="flex-end" alignContent="flex-end" item container>
          <HeaderIconButton label="Add Address" onClick={formOpen(null)}>
            <AddBoxOutlined />
          </HeaderIconButton>
        </Grid>

        <Grid
          spacing={3}
          alignContent="flex-start"
          justifyContent="space-between"
          item
          container
        >
          {addresses?.map((address: any) => (
            <AddressCard
              key={address.id}
              address={address}
              selected={selected}
              onSelect={onSelect}
              onEdit={editHandler}
              onDelete={deleteHandler}
            />
          ))}
        </Grid>
      </Grid>
      {formDialogOpened !== undefined && (
        <AddressForm
          refresh={mutate}
          address={formDialogOpened ?? undefined}
          open={formDialogOpened !== undefined}
          onClose={formOpen(undefined)}
        />
      )}
    </>
  );
};
