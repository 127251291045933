import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    background: 'white',
    boxShadow: 'none',
    padding: '1.6em 0',
    [theme.breakpoints.down('md')]: {
      padding: '10px 0',
    },
  },
  toolbar: {
    [theme.breakpoints.down('md')]: {
      padding: '0 10px',
    },
  },
  fixed: {
    padding: '0',
  },
}));
