import { confirmAlert } from 'react-confirm-alert';
import { SahinConfirmDialogProps } from './ConfirmDialog.types';
import { ConfirmDialogCustomUI } from './ConfirmDialogCustomUI';

export const sahinConfirmDialog = ({
  title,
  message,
  confirmText = 'Tamam',
  cancelText = 'İptal',
  onConfirm,
  onCancel,
}: SahinConfirmDialogProps) => {
  return confirmAlert({
    title,
    message,
    closeOnClickOutside: true,
    onClickOutside: onCancel,
    customUI: ({ ...rest }) => {
      return (
        <ConfirmDialogCustomUI
          {...rest}
          title={title}
          message={message}
          confirmText={confirmText}
          cancelText={cancelText}
          onConfirm={onConfirm}
        />
      );
    },
  });
};
