import { useEffect } from 'react';
import { useAppDispatch } from '../../../common/context/hooks';
import { Product } from '../../../common/context/models';
import { generalActions } from '../../../common/context/slice';
import { useFetch } from '../../../common/hooks/useFetchSwr';
import { RequestPathNamespace } from '../../../common/models/RequestPathNamespace';

export const ProductProvider = () => {
  const dispatch = useAppDispatch();
  const { data: products } = useFetch<Product[]>(RequestPathNamespace.PRODUCT);

  useEffect(() => {
    if (products) {
      dispatch(generalActions.setProducts(products));
    }
  }, [dispatch, products]);

  return <></>;
};
