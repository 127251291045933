import { ShoppingBasketOutlined } from '@mui/icons-material';
import { Badge, Divider, Drawer, Grid, Typography } from '@mui/material';
import { MouseEvent, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CheckoutSummary } from '../../../basket/components/CheckoutSummary/CheckoutSummary';
import { useAppSelector } from '../../context/hooks';
import { createLink } from '../../helpers/creeateRoute';
import { RoutePathNamespace } from '../../models/RoutePathNamespace';
import { HeaderIconButton } from '../Header/components/HeaderIconButton/HeaderIconButton';
import { SahinButton } from '../SahinButton/SahinButton';

export const HeaderBasketIcon = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const brand = useAppSelector((state) => state.general.brand);
  const basket = useAppSelector((state) => state.general.basket);
  const basketLength = useMemo(() => basket.length, [basket]);
  const minPrice = useMemo(
    () => brand?.paymentMethod?.minPrice,
    [brand?.paymentMethod?.minPrice],
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const goCheckout = useCallback(() => {
    navigate(createLink(RoutePathNamespace, RoutePathNamespace.PAYMENT_PAGE));
    setAnchorEl(null);
  }, [navigate]);

  const handleMenuOpen = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (basketLength) {
        setAnchorEl(event.currentTarget);
      }
    },
    [basketLength],
  );

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const bottomToolbar = (summary: any) => (
    <Grid flexDirection="column" container>
      {summary.totalPrice < minPrice && (
        <Typography textAlign="center" fontWeight="bold" sx={{ mb: 2 }}>
          {t('basket.minPay', { price: minPrice })}
        </Typography>
      )}
      <Divider />
      <SahinButton
        onClick={goCheckout}
        disabled={minPrice > summary.totalPrice}
        fullWidth
      >
        {t('common.approve')}
      </SahinButton>
    </Grid>
  );

  return (
    <Grid item>
      <Badge badgeContent={basketLength} color="primary" overlap="circular">
        <HeaderIconButton onClick={handleMenuOpen} size="large" color="inherit">
          <ShoppingBasketOutlined />
        </HeaderIconButton>
      </Badge>
      {anchorEl && (
        <Drawer
          PaperProps={{
            sx: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              width: 400,
              height: '100%',
              overflow: 'auto',
            },
          }}
          anchor="right"
          SlideProps={{ direction: 'right', timeout: 2000 }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <CheckoutSummary
            xs={12}
            basket={basket}
            bottomToolbar={bottomToolbar}
            onClose={handleMenuClose}
            drawer
          />
        </Drawer>
      )}
    </Grid>
  );
});
