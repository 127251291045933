import { Route, Routes } from 'react-router-dom';
import { AllRouteNames } from '../models/AllRouteNames';
import { RouteType } from '../models/RouteType';

export const createLink = (prefixEnum: AllRouteNames, link: string) => {
  let path = '';
  if (link !== '/') {
    path = `/${link.replace('/', '')}`;
  }
  const prefix = prefixEnum['PREFIX'] as string;
  if (prefix !== '/' && prefix !== '') {
    path = `/${prefix}${path}`;
  }
  return path;
};

export const createRoute = (routes: RouteType[]) => (
  <Routes>
    {routes.map((route) => {
      let path: string = route.path;
      if (route.isChildren) {
        path = `${path}/*`;
      }
      return <Route key={route.path} path={path} element={route.element} />;
    })}
  </Routes>
);
