import { lighten } from '@mui/material';
import { red } from '@mui/material/colors';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    '& input:valid + fieldset, & textarea + fieldset': {
      borderColor: `${lighten(theme.palette.common.black, 0.2)} !important`,
      borderWidth: '2px!important',
      borderLeftWidth: '6px!important',
    },
    '&:hover input:valid + fieldset, &:hover textarea + fieldset': {
      borderColor: `${lighten(theme.palette.common.black, 0.3)} !important`,
    },
    '& input:valid:focus + fieldset, & textarea:focus + textarea + fieldset': {
      borderColor: `${theme.palette.common.black} !important`,
    },
  },
  title: {
    paddingBottom: 5,
  },
  error: {
    color: red[700],
    paddingTop: 5,
    paddingLeft: 10,
  },
}));
