import { Box, Grid, IconButton, Typography } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { HeaderBasketIconCard } from '../../../common/components/HeaderBasketIcon/HeaderBasketProductCard/HeaderBasketProductCard';
import { BasketProduct } from '../../../common/context/models';
import { currencyFormat } from '../../../common/utils/helper';
import { useStyles } from './CheckoutSummary.styles';
import { Close } from '@mui/icons-material';
import { useResponsive } from '../../../common/hooks/useResponsive';

interface CheckoutSummaryProps {
  xs?: number;
  basket: BasketProduct[];
  topToolbar?: ReactNode;
  bottomToolbar?: (summary: any) => ReactNode;
  drawer?: boolean;
  disabledCloseIcon?: boolean;
  onClose?: () => void;
  [key: string]: any;
}

export const createSummary = (basket: BasketProduct[]): any => {
  let totalPrice = 0;
  let mainTotalPrice = 0;
  let subTotalPrice = 0;
  let discount = 0;

  basket.forEach(({ product, count }) => {
    totalPrice += parseFloat(product.price) * count;
    mainTotalPrice += parseFloat(product.price) * count;
    subTotalPrice += parseFloat(product.price) * count;
  });

  return {
    totalPrice,
    subTotalPrice,
    mainTotalPrice,
    discount,
  };
};

export const CheckoutSummary = ({
  xs = 3,
  basket,
  topToolbar,
  bottomToolbar,
  drawer,
  onClose,
  ...rest
}: CheckoutSummaryProps) => {
  const { isXs } = useResponsive();
  const { classes, cx } = useStyles();
  const [summary, setSummary] = useState(createSummary(basket));

  useEffect(() => {
    setSummary(createSummary(basket));
  }, [basket]);

  return (
    <Grid
      xs={xs}
      alignContent="flex-start"
      justifyContent="flex-end"
      className={cx({
        [classes.root]: true,
        [classes.drawerIcon]: !isXs && drawer,
      })}
      item
      container
      {...rest}
    >
      <Grid
        className={classes.container}
        alignContent="flex-start"
        flexDirection="column"
        item
        container
      >
        {onClose && (
          <IconButton className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        )}
        {topToolbar}
        <Grid container>
          <Typography variant="h2" fontWeight={500} className={classes.title}>
            SEPET ÖZETİ
          </Typography>
        </Grid>
        <Grid className={classes.summary} flexDirection="column" item container>
          <Grid
            alignContent="flex-start"
            className={classes.summaryProductList}
            item
            container
          >
            {basket.map((basketProduct) => (
              <HeaderBasketIconCard
                key={basketProduct.product.id}
                basketProduct={basketProduct}
              />
            ))}
          </Grid>
          <Grid item container>
            <Box m={1} />
            <SummaryRow
              left="Ara Toplam"
              right={currencyFormat(summary.totalPrice)}
            />
            <SummaryRow
              left="İndirim"
              right={currencyFormat(summary.discount)}
            />
            <Grid className={classes.totalPrice} container>
              <SummaryRow
                left="Ödenecek Tutar"
                right={currencyFormat(summary.totalPrice)}
                contentFontWeight={600}
                fontSize="1.1rem"
                np={0}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container>
          {bottomToolbar?.(summary)}
        </Grid>
      </Grid>
    </Grid>
  );
};

const SummaryRow = ({
  left,
  right,
  np = 3,
  fontSize,
  labelFontWeight,
  contentFontWeight,
}: any) => (
  <Grid pb={np} columnSpacing={3} container>
    <Grid xs={12} lg={8} item container>
      <Grid justifyContent="space-between" item container>
        <Typography fontSize={fontSize} fontWeight={labelFontWeight}>
          {left}
        </Typography>
        <Typography fontSize={fontSize} fontWeight={labelFontWeight}>
          :
        </Typography>
      </Grid>
    </Grid>
    <Grid xs={12} lg={4} item container>
      <Grid item>
        <Typography fontSize={fontSize} fontWeight={contentFontWeight}>
          {right}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);
