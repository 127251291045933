import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../context/hooks';
import { useStyles } from './Logo.styles';

export const Logo = ({ ...rest }: any) => {
  const { classes } = useStyles();
  const brand = useAppSelector((state) => state.general.brand);

  return (
    <Grid
      xs={5}
      lg={3}
      to="/"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.logo}
      component={Link}
      item
      container
      {...rest}
    >
      {brand?.store?.logo && (
        <img src={brand?.store?.logo} alt={brand?.store?.title} height={40} />
      )}
      {!brand?.store?.logo && (
        <span>
          <strong>RESTORANT</strong> KOLAY
        </span>
      )}
    </Grid>
  );
};
