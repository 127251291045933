import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    flex: '1 1 20%',
    flexGrow: 20,
    // background: 'var(--gradient-color-reverse)',
    boxShadow: 'none',
    color: 'white',
    fontWeight: 900,
    padding: '15px 25px',
    letterSpacing: '.2em',
    textDecoration: 'none',
    '&:hover': {
      opacity: 0.7,
      boxShadow: 'none',
    },
  },
  strong: { fontWeight: 900 },
  normal: {},
  space: {
    background: 'transparent!important',
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.common.black}`,
    '&:hover': {
      borderWeight: 2,
      opacity: 0.5,
      boxShadow: 'none',
    },
  },
  big: {
    flexDirection: 'column',
  },
  small: {
    padding: '10px 15px',
  },
  leftIcon: {
    display: 'flex',
    marginRight: 10,
  },
  rightIcon: {
    display: 'flex',
    marginLeft: 10,
  },
  soft: {
    opacity: 0.7,
  },
}));
