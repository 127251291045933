import { GppGoodTwoTone } from '@mui/icons-material';
import { AppBar, Box, Grid, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Logo } from '../../../common/components/Logo/Logo';
import { useStyles } from './PaymentHeader.styles';

export const PaymentHeader = () => {
  const { classes } = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className={classes.root}>
        <Toolbar>
          <Grid justifyContent="center" container>
            <Grid
              justifyContent="center"
              alignItems="center"
              xs={12}
              lg={10}
              item
              container
            >
              <Grid xs={6} lg={4} justifyContent="start" item container>
                <Link to="/" className={classes.link}>
                  <Typography fontWeight={400}>ALIŞVERİŞE DEVAM ET</Typography>
                </Link>
              </Grid>
              <Logo
                justifyContent="center"
                sx={{ display: { xs: 'none', md: 'flex' } }}
              />
              <Grid xs={6} lg={4} justifyContent="flex-end" item container>
                <Grid item>
                  <Grid justifyContent="center" container>
                    <GppGoodTwoTone
                      sx={{ fontSize: '2rem' }}
                      color="disabled"
                    />
                  </Grid>
                  <Box sx={{ pt: 1 }} />
                  <Typography fontWeight={400}>GÜVENLİ ÖDEME</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
