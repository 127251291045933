import { useEffect } from 'react';
import { useAppDispatch } from '../../../common/context/hooks';
import { Category } from '../../../common/context/models';
import { generalActions } from '../../../common/context/slice';
import { useFetch } from '../../../common/hooks/useFetchSwr';
import { RequestPathNamespace } from '../../../common/models/RequestPathNamespace';

export const CategoryProvider = () => {
  const dispatch = useAppDispatch();
  const { data: categories } = useFetch<Category[]>(
    RequestPathNamespace.CATEGORY,
  );

  useEffect(() => {
    if (categories) {
      dispatch(generalActions.setCategories(categories));
    }
  });

  return <></>;
};
