import { Box, Grid, Paper } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import { CheckoutSummary } from '../../../basket/components/CheckoutSummary/CheckoutSummary';
import { SahinAccordion } from '../../../common/components/Accordion/SahinAccordion';
import { SahinButton } from '../../../common/components/SahinButton/SahinButton';
import { SahinTextField } from '../../../common/components/TextField/SahinTextField';
import { useAppSelector } from '../../../common/context/hooks';
import { SahinFetch } from '../../../common/helpers/Fetch';
import { RequestPathNamespace } from '../../../common/models/RequestPathNamespace';
import { PaymentAddressCard } from './AddressCard';
import { PaymentTypeComponent } from './PaymentTypeSelect';
import { sahinAlertDialog } from '../../../common/components/AlertDialog/AlertDialog';
import { mutate } from 'swr';

export const PaymentMethod = ({ paymentData, next }: any) => {
  const basket = useAppSelector((state) => state.general.basket);
  const brand = useAppSelector((state) => state.general.brand);
  const minPrice = useMemo(
    () => brand?.paymentMethod?.minPrice,
    [brand?.paymentMethod?.minPrice],
  );

  const noteRef = useRef<HTMLInputElement>();

  const [selectedPaymentType, setSelectedPaymentType] = useState<string>();

  const paymentComplete = () => {
    paymentData.note = noteRef.current?.value ?? '';
    new SahinFetch()
      .post(RequestPathNamespace.ORDER, {
        basket: basket,
        paymentType: selectedPaymentType,
        address: paymentData.address,
        note: paymentData.note,
      })
      .then((res) => {
        mutate(RequestPathNamespace.ORDER);
        if (res?.status === 413) {
          sahinAlertDialog({
            message: "Bir saat içinde 5'ten fazla sipariş veremezsiniz.",
            type: 'error',
          });
        } else {
          next();
        }
      });
  };

  const bottomToolbar = (summary: any) => (
    <SahinButton
      onClick={paymentComplete}
      disabled={!selectedPaymentType || minPrice > summary.totalPrice}
      strong
      fullWidth
    >
      TAMAMLA
    </SahinButton>
  );

  return (
    <>
      <Grid
        xs={12}
        md={8}
        mt={{ xs: 5, md: 0 }}
        alignContent="flex-start"
        item
        container
      >
        <SahinAccordion
          title="Teslimat Bilgileri"
          content={<PaymentAddressCard address={paymentData.address} />}
          isExpanded
        />

        <Box p={3} />
        <Grid xs={12} sx={{ p: 4 }} component={Paper} container>
          <Grid container>
            <PaymentTypeComponent
              onSelect={(key: string) => setSelectedPaymentType(key)}
            />
          </Grid>
        </Grid>
        <Box p={3} />
        <SahinAccordion
          title="Sipariş Notu"
          content={
            <SahinTextField
              ref={noteRef}
              placeholder="Sipariş Notu"
              minRows={4}
              multiline
              fullWidth
            />
          }
          isExpanded
        />
      </Grid>
      <CheckoutSummary
        xs={12}
        md={4}
        mt={{ xs: 5, md: 0 }}
        basket={basket}
        bottomToolbar={bottomToolbar}
      />
    </>
  );
};
