import { Box, Grid, Typography } from '@mui/material';

interface TextMessageProps {
  message: string;
  size?: number;
  gap?: number;
}

export const TextMessage = ({
  message,
  size = 30,
  gap = 5,
}: TextMessageProps) => {
  return (
    <Grid justifyContent="center" alignItems="center" item container>
      <Typography fontSize={size} textAlign="center">
        <Box sx={{ padding: gap }}>{message}</Box>
      </Typography>
    </Grid>
  );
};
