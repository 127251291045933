import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, Grid, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yup } from '../../../application/init/yupInit';
import { sahinAlertDialog } from '../../../common/components/AlertDialog/AlertDialog';
import { HeaderIconButton } from '../../../common/components/Header/components/HeaderIconButton/HeaderIconButton';
import { Loading } from '../../../common/components/Loading/Loading';
import { SahinMaskField } from '../../../common/components/MaskField/SahinMaskField';
import { SahinTextField } from '../../../common/components/TextField/SahinTextField';
import { useAppSelector } from '../../../common/context/hooks';
import { SahinFetch } from '../../../common/helpers/Fetch';
import { RequestPathNamespace } from '../../../common/models/RequestPathNamespace';
import { phoneTurkeyFormat } from '../../../common/utils/helper';

export const userUpdateResolver = yupResolver(
  yup
    .object({
      fullName: yup.string().min(4).required(),
      email: yup.string().email().required(),
      phone: yup.string().length(12).required(),
      smsCode: yup.string().length(6),
    })
    .required(),
);

export const UserData = () => {
  const user = useAppSelector((state) => state.general.user);
  if (!user) return <Loading />;
  return <UserDataForm user={user} />;
};

const UserDataForm = ({ user }: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      fullName: user.fullName,
      email: user.email,
      phone: phoneTurkeyFormat(user.phone, false),
    },
    resolver: userUpdateResolver,
    mode: 'onBlur',
  });

  const [loading, setLoading] = useState(false);
  const [smsCodePopupOpen, setSmsCodePopupOpen] = useState(false);

  const handleSmsCodePopupClose = useCallback(() => {
    setSmsCodePopupOpen(false);
  }, []);

  const onSubmit = (data: any) => {
    data.phone = phoneTurkeyFormat(data.phone, false);

    setLoading(true);
    new SahinFetch()
      .put(RequestPathNamespace.USER, data)
      .then((result) => {
        handleSmsCodePopupClose();
        sahinAlertDialog({
          message: 'Güncellendi!',
        });
      })
      .catch((error) => {
        if (error.response.status === 453) {
          setSmsCodePopupOpen(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid
      xs={12}
      lg={6}
      mt={{ xs: 4, md: 0 }}
      spacing={2}
      alignContent="flex-start"
      alignItems="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      item
      container
    >
      <Grid xs={12} item>
        <SahinTextField
          {...register('email')}
          disabled={true}
          label="E-Posta Adresiniz"
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <SahinTextField
          {...register('fullName')}
          error={errors.fullName?.message}
          label="Adınız ve Soyadınız"
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <SahinMaskField
          {...register('phone')}
          error={errors.phone?.message}
          label="Telefon Numaranız"
          placeholder="Lütfen telefon numaranızı girin..."
          fullWidth
        />
      </Grid>
      <Grid xs={12} justifyContent="flex-end" item container>
        <Grid xs={5} item container>
          <HeaderIconButton
            loading={loading}
            disabled={loading}
            label="KAYDET"
            type="submit"
            bigged
          />
        </Grid>
      </Grid>
      <Dialog open={smsCodePopupOpen} onClose={handleSmsCodePopupClose}>
        <Grid container flexDirection="column" alignItems="center" p={5}>
          <Typography mb={3}>
            Please enter the code that was sent to your phone
          </Typography>
          <SahinTextField {...register('smsCode')} label="SMS Code" />
          <HeaderIconButton
            loading={loading}
            disabled={loading}
            label="KAYDET"
            type="submit"
            bigged
          />
        </Grid>
      </Dialog>
    </Grid>
  );
};
