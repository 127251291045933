import { Grid, Typography } from '@mui/material';
import { useStyles } from './CategoryCard.styles';
import { CategoryCardProps } from './CategoryCard.types';
import { getCdnImageUrl } from '../../helpers/image';

export const CategoryCard = ({ category, onChange }: CategoryCardProps) => {
  const { name = 'Tümü' } = category || {};
  const { cx, classes } = useStyles();

  return (
    <Grid xs={12} item container>
      <Grid
        xs={12}
        className={classes.root}
        onClick={() => onChange?.(category)}
        item
        container
      >
        {name !== 'Tümü' && (
          <Grid
            xs={12}
            justifyContent="center"
            alignItems="center"
            item
            container
          >
            <img
              className={classes.image}
              src={getCdnImageUrl(category?.url)}
              height={50}
              width={50}
              alt={name}
            />
          </Grid>
        )}
        <Grid
          xs={12}
          justifyContent="center"
          alignContent="center"
          item
          container
        >
          <Typography
            variant="h3"
            className={cx(classes.title, name === 'Tümü' && classes.big)}
          >
            {name}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
