import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme, props, classes: any) => ({
  addressCard: {
    padding: '10px 30px 10px 15px',
    // border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    position: 'relative',
    [`&:hover .${classes.actionButtons}`]: {
      opacity: 1,
    },
  },
  selecting: {
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 16,
      height: 16,
      background: '#333',
      borderRadius: 8,
      top: 10,
      right: 10,
      outline: '4px solid white',
      outlineOffset: -10,
    },
  },
  addressCardActive: {
    '&::before': {
      background: `${theme.palette.primary.main}`,
    },
  },
  actionButtons: {
    opacity: 0,
    transition: '500ms',
    position: 'relative',
    right: -20,
  },
}));
