import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<any>()((theme, params) => {
  const selected =
    params?.color && (theme.palette as any)?.[params?.color]?.main;
  return {
    root: {
      borderRadius: 5,
    },
    colored: {
      backgroundColor: `${alpha(selected || theme.palette.primary.main, 0.1)}`,
    },
    active: {
      backgroundColor: `${alpha(theme.palette.primary.main, 0.8)}`,
    },
  };
});
