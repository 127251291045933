import { Grid, Typography } from '@mui/material';
import { BasketProduct } from '../../../context/models';
import { useStyles } from './HeaderBasketProductCard.styles';

export const HeaderBasketIconCard = ({
  basketProduct,
}: {
  basketProduct: BasketProduct;
}) => {
  // MARK: - Hooks

  const { classes } = useStyles();
  const { product, count } = basketProduct;

  // MARK: - Render

  return (
    <Grid
      className={classes.productItem}
      justifyContent="space-between"
      alignContent="space-around"
      columnSpacing={3}
      container
    >
      <Grid xs={8} justifyContent="space-between" item container>
        <Grid flex={1} item>
          <Typography fontWeight={500}>{product.name}</Typography>
        </Grid>
        <Grid item>
          <Typography>{count} x</Typography>
        </Grid>
      </Grid>
      <Grid xs={4} justifyItems="flex-end" item container>
        <Grid item>
          <Typography>{product.price} ₺</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
