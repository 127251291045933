import { Helmet } from 'react-helmet';
import { useAppSelector } from '../../context/hooks';

const Head = () => {
  const brand = useAppSelector((state) => state.general.brand);
  return (
    <Helmet>
      <meta name="robots" content="index, follow" />
      <title>{brand?.store.title ?? ''}</title>
      {/* <meta
        name="author"
        content="Sembol Bilişim Bilişim ve Matbaacılık Sanayi ticaret ve ltd şti."
      />
      <meta
        name="description"
        content="Eco Termal Etiket 100mm 150mm kaliteli, güvenilir ve hızlı kargo ile Sembol Bilişim'de!"
      />
      <meta
        name="keywords"
        content="Eco,Termal,Etiket,100mm,150mm,kaliteli,güvenilir,ve,hızlı,kargo,ile,Sembol,Bilişim'de!"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@yorumsatiri" />
      <meta name="twitter:creator" content="yorumsatiri" />
      <meta
        name="twitter:title"
        content="Eco Termal Etiket 100mm 150mm - Sembol Bilişim"
      />
      <meta
        name="twitter:description"
        content="Eco Termal Etiket 100mm 150mm kaliteli, güvenilir ve hızlı kargo ile Sembol Bilişim'de!"
      />
      <meta
        name="twitter:image:src"
        content="https://www.yorumsatiri.com.tr/assets/upload/urun/81/1.jpg"
      />
      <meta
        property="og:url"
        content="https://www.yorumsatiri.com.tr/urun/eco-termal-etiket-100mm-150mm.html"
      />
      <meta property="og:type" content="business:business" />
      <meta
        property="og:title"
        content="Eco Termal Etiket 100mm 150mm - Sembol Bilişim"
      />
      <meta
        property="og:image"
        content="https://www.yorumsatiri.com.tr/assets/upload/urun/81/1.jpg"
      />
      <meta
        property="og:description"
        content="Eco Termal Etiket 100mm 150mm kaliteli, güvenilir ve hızlı kargo ile Sembol Bilişim'de!"
      />
      <meta
        property="og:site_name"
        content="Sembol Bilişim Bilişim ve Matbaacılık Sanayi ticaret ve ltd şti."
      />
      <meta
        property="business:contact_data:street_address"
        content="Girne Mah. Irmak Sk. Küçükyalı İş Merkezi Sitesi No: 72-A2 Maltepe - İSTANBUL"
      />
      <meta property="business:contact_data:locality" content="İstanbul" />
      <meta property="business:contact_data:region" content="None" />
      <meta property="business:contact_data:postal_code" content="34852" />
      <meta property="business:contact_data:country_name" content="Türkiye" />
      <meta
        property="article:author"
        content="https://www.facebook.com/yorumsatiri"
      />
      <meta
        property="article:publisher"
        content="https://www.facebook.com/yorumsatiri"
      />
      <meta
        itemProp="name"
        content="Eco Termal Etiket 100mm 150mm - Sembol Bilişim"
      />
      <meta
        itemProp="description"
        content="Eco Termal Etiket 100mm 150mm kaliteli, güvenilir ve hızlı kargo ile Sembol Bilişim'de!"
      />
      <meta
        itemProp="image"
        content="https://www.yorumsatiri.com.tr/assets/upload/urun/81/1.jpg"
      />
      <link
        rel="shortcut icon"
        href="https://www.yorumsatiri.com.tr/assets/images/favicon.ico"
        type="image/x-icon"
      />
      <link
        rel="canonical"
        href="https://www.yorumsatiri.com.tr/urun/eco-termal-etiket-100mm-150mm.html"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
      /> */}
    </Helmet>
  );
};

export default Head;
