import { Grid } from '@mui/material';
import { memo, useState } from 'react';
import { Keyboard, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Category } from '../../context/models';
import { CategoryCard } from '../CategoryCard/CategoryCard';
import { Loading } from '../Loading/Loading';

import 'swiper/css';
import 'swiper/css/scrollbar';

export const CategoryList = memo<{
  categories?: Category[];
  onChange?: (category?: Category) => void;
  lg?: number;
  [key: string]: any;
}>(({ categories, onChange, lg = 12, ...rest }) => {
  const [requestTimeControl, setRequestTimeControl] = useState(false);

  setTimeout(() => {
    setRequestTimeControl(true);
  }, 5 * 1000);

  if (!categories && categories === undefined) return <Loading />;
  if (!categories) return <></>;
  return (
    <Grid
      justifyContent="flex-start"
      alignItems="flex-start"
      alignContent="flex-start"
      lg={lg}
      spacing={3}
      item
      container
      {...rest}
    >
      <Grid xs={4} md={2} lg={1.3} item container>
        <CategoryCard onChange={onChange} />
      </Grid>
      <Grid xs={8} md={10} lg={10.7} item container>
        <Swiper
          slidesPerView={8}
          spaceBetween={20}
          centeredSlides={false}
          grabCursor={true}
          keyboard={{
            enabled: true,
          }}
          breakpoints={{
            0: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            900: {
              slidesPerView: 5,
              slidesPerGroup: 1,
            },
            1200: {
              slidesPerView: 8,
              slidesPerGroup: 1,
            },
          }}
          style={{
            paddingBottom: 20,
          }}
          scrollbar={true}
          modules={[Keyboard, Scrollbar]}
          // pagination={{
          //   type: 'fraction',
          //   dynamicBullets: true,
          //   // clickable: true,
          // }}
          // scrollbar={{ draggable: true, hide: false }}
          // slidesPerGroupSkip={10}
          // navigation={false}
          // pagination={false}
          // navigation
        >
          {categories?.map((category) => (
            <SwiperSlide key={category.id}>
              <CategoryCard onChange={onChange} category={category} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
      {!categories && !requestTimeControl && (
        <Loading finallyControl={requestTimeControl} />
      )}
    </Grid>
  );
});
