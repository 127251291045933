import { Grid } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { sahinAlertDialog } from '../../../common/components/AlertDialog/AlertDialog';
import { HeaderIconButton } from '../../../common/components/Header/components/HeaderIconButton/HeaderIconButton';
import { SahinTextField } from '../../../common/components/TextField/SahinTextField';
import { SahinFetch } from '../../../common/helpers/Fetch';
import { RequestPathNamespace } from '../../../common/models/RequestPathNamespace';

type PasswordUpdateRequest = {
  oldPassword?: string;
  newPassword?: string;
  passwordAgain?: string;
};

export const UserPassword = () => {
  const { register, reset, handleSubmit } = useForm<any>({
    mode: 'onBlur',
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = (data: PasswordUpdateRequest) => {
    if (!data.newPassword || !data.passwordAgain) return;

    setLoading(true);
    new SahinFetch()
      .put(RequestPathNamespace.UPDATE_PASSWORD, data)
      .then((response) => {
        reset({});
        sahinAlertDialog({
          type: response.status,
          message: response.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid
      xs={12}
      lg={6}
      mt={{ xs: 4, md: 0 }}
      spacing={2}
      alignContent="flex-start"
      alignItems="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      item
      container
    >
      <Grid xs={12} item>
        <SahinTextField
          {...register('oldPassword')}
          label="Mevcut Şifre"
          type="password"
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <SahinTextField
          {...register('newPassword')}
          label="Yeni Şifre"
          type="password"
          fullWidth
        />
      </Grid>
      <Grid xs={12} item>
        <SahinTextField
          {...register('passwordAgain')}
          label="Yeni Şifre (tekrar)"
          type="password"
          fullWidth
        />
      </Grid>
      <Grid xs={12} justifyContent="flex-end" item container>
        <Grid xs={5} item container>
          <HeaderIconButton
            loading={loading}
            disabled={loading}
            label="KAYDET"
            type="submit"
            bigged
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
