import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { Footer } from '../../../common/components/Footer/Footer';
import { Header } from '../../../common/components/Header/Header';
import { useAppSelector } from '../../../common/context/hooks';
import { authRoutes, noAuthRoutes } from '../../../common/utils/routes';

export const MainPagesRoute = () => {
  const auth = useAppSelector((state) => state.general.auth);
  const selectedRoutes = useMemo(
    () => (auth ? authRoutes : noAuthRoutes),
    [auth],
  );

  return (
    <>
      <Header />
      <div>
        <Grid sx={{ minHeight: '60vh' }} justifyContent="center" container>
          {selectedRoutes}
        </Grid>
      </div>
      <Footer />
    </>
  );
};
