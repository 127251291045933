import { Box, Grid } from '@mui/material';
import { memo, useCallback, useRef, useState } from 'react';
import { PaymentHeader } from '../../../payment/components/PaymentHeader/PaymentHeader';
import { PaymentMethod } from '../../../payment/components/Payment/PaymentMethod';
import { PaymentAddress } from '../../../payment/components/PaymentAddress/PaymentAddress';
import { PaymentSuccess } from '../../../payment/components/PaymentSuccess/PaymentSuccess';
import { CustomizedSteppers } from '../../../payment/components/Stepper/Stepper';

export const PaymentPage = memo(() => {
  const paymentData = useRef({
    address: {},
    note: '',
  });
  const [step, setStep] = useState<number>(0);

  const next = useCallback(() => {
    setStep((prevState) => prevState + 1);
  }, []);

  return (
    <>
      <PaymentHeader />
      <Box p={7}></Box>
      <Grid
        xs={12}
        sx={{ paddingBottom: 30 }}
        justifyContent="center"
        alignContent="flex-start"
        item
        container
      >
        <Grid item container>
          <CustomizedSteppers step={step} setStep={setStep} />
        </Grid>
        <Grid xs={11} lg={10} justifyContent="center" item container>
          {
            [
              <PaymentAddress
                key={1}
                paymentData={paymentData.current}
                next={next}
              />,
              <PaymentMethod
                key={2}
                paymentData={paymentData.current}
                next={next}
              />,
              <PaymentSuccess
                key={3}
                paymentData={paymentData.current}
                next={next}
              />,
            ][step]
          }
        </Grid>
      </Grid>
    </>
  );
});
