import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { useCallback, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { SahinButton } from '../../../common/components/SahinButton/SahinButton';
import { SahinTextField } from '../../../common/components/TextField/SahinTextField';
import { useAppDispatch } from '../../../common/context/hooks';
import { generalActions } from '../../../common/context/slice';
import { SahinFetch } from '../../../common/helpers/Fetch';
import { createLink } from '../../../common/helpers/creeateRoute';
import { RequestPathNamespace } from '../../../common/models/RequestPathNamespace';
import { RoutePathNamespace } from '../../../common/models/RoutePathNamespace';
import { yup } from '../../init/yupInit';
import { useStyles } from './RegisterPage.styles';

const schema = yup
  .object({
    fullName: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required(),
    passwordAgain: yup.string().required(),
  })
  .required();

export const RegisterPage = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<any>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { REACT_APP_RECAPTCHA_KEY } = process.env;

  const onSubmit = useCallback(
    (data: any) => {
      if (data.passwordAgain !== data.password) {
        setError('password', {
          type: 'custom',
          message: t('errorMessages.passwordEqual'),
        });
        setError('passwordAgain', {
          type: 'custom',
          message: t('errorMessages.passwordEqual'),
        });
        return;
      } else if (recaptchaRef.current?.getValue() === '') {
        setError('passwordAgain', {
          type: 'custom',
          message: t('errorMessages.recaptchaError'),
        });
        return;
      }

      new SahinFetch()
        .post(RequestPathNamespace.REGISTER, data)
        .then(() => {
          new SahinFetch()
            .post(RequestPathNamespace.LOGIN, data)
            .then((data) => {
              dispatch(generalActions.setAuthentication(data));
              new SahinFetch().get(RequestPathNamespace.USER).then((data) => {
                dispatch(generalActions.setUser(data));
              });
              navigate('/');
            })
            .catch(() => {
              setError('passwordAgain', {
                type: 'custom',
                message: t('errorMessages.registerError'),
              });
            });
        })
        .catch(() => {
          setError('passwordAgain', {
            type: 'custom',
            message: t('errorMessages.registerError'),
          });
        });

      // dispatch(generalActions.addUser(data));
    },
    [dispatch, navigate, setError, t],
  );

  return (
    <Grid className={classes.root} component={Paper} xs={11} lg={4} item>
      <Grid
        spacing={2}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        container
      >
        <Grid justifyContent="center" container>
          <Typography fontWeight={600} fontSize={30}>
            {t('register.title')}
          </Typography>
        </Grid>
        <SahinTextField
          name={t('form.fullName')}
          label={t('form.fullName')}
          error={errors?.firstName?.message}
          inputProps={{ ...register('fullName') }}
          fullWidth
        />
        <SahinTextField
          name={t('form.mail')}
          label={t('form.mail')}
          error={errors?.mail?.message}
          inputProps={{ ...register('email') }}
          fullWidth
        />
        <SahinTextField
          name={t('form.password')}
          type="password"
          label={t('form.password')}
          error={errors?.password?.message}
          inputProps={{ ...register('password') }}
          fullWidth
        />
        <SahinTextField
          name={t('form.passwordAgain')}
          type="password"
          label={t('form.passwordAgain')}
          error={errors?.passwordAgain?.message}
          inputProps={{ ...register('passwordAgain') }}
          fullWidth
        />
        <Grid xs={12} item container>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={REACT_APP_RECAPTCHA_KEY ?? ''}
          />
        </Grid>
        <Grid justifyContent="flex-end" item container>
          <Grid xs={6} item container>
            <SahinButton type="submit">
              {t('register.submitButton')}
            </SahinButton>
          </Grid>
        </Grid>
        <Grid xs={12} justifyContent="center" item container>
          <Button
            variant="text"
            sx={{ marginTop: 3, fontWeight: 900 }}
            to={createLink(
              RoutePathNamespace,
              RoutePathNamespace.REGISTER_PAGE,
            )}
            component={Link}
          >
            Hesabın var mı ? Giriş Yap !
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
