/* eslint-disable no-template-curly-in-string */
import * as yupInstance from 'yup';

yupInstance.setLocale({
  mixed: {
    required: 'Bu alan zorunlur!',
  },
  string: {
    min: 'minimum ${min} karakter olabilir',
    max: 'maximum ${min} karakter olabilir',
    email: 'Formata uygun e-mail adresi giriniz.',
  },
  number: {
    min: 'minimum ${min} karakter olabilir',
    max: 'maximum ${min} karakter olabilir',
  },
});

export const yup = yupInstance;
