import { useEffect } from 'react';
import { useAppDispatch } from '../../../common/context/hooks';
import { UserDTO } from '../../../common/context/models';
import { generalActions } from '../../../common/context/slice';
import { useFetch } from '../../../common/hooks/useFetchSwr';
import { RequestPathNamespace } from '../../../common/models/RequestPathNamespace';

export const UserProvider = () => {
  const dispatch = useAppDispatch();
  const { data: userData, error } = useFetch<UserDTO>(
    RequestPathNamespace.USER,
  );

  useEffect(() => {
    if (userData) {
      dispatch(generalActions.setUser(userData));
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if (error?.status === 401) {
      dispatch(generalActions.clearAuthentication());
    }
  }, [dispatch, error]);

  return <></>;
};
