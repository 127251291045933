import { Navigate } from 'react-router-dom';
import { AccountRoutePathNamespace } from '../../account/models/AccountRoutePathNamespace';
import { BasketPage } from '../../application/pages/BasketPage/BasketPage';
import { ContactPage } from '../../application/pages/ContactPage/ContactPage';
import { LoginPage } from '../../application/pages/LoginPage/LoginPage';
import { MainPagesRoute } from '../../application/pages/MainPagesRoute/MainPagesRoute';
import { MenuPage } from '../../application/pages/MenuPage/MenuPage';
import { PaymentPage } from '../../application/pages/PaymentPage/PaymentPage';
import { ProfilePage } from '../../application/pages/ProfilePage/ProfilePage';
import { RegisterPage } from '../../application/pages/RegisterPage/RegisterPage';
import { StorePage } from '../../application/pages/StorePage/StorePage';
import { AccountAddresses } from '../../account/pages/AccountAddresses/AccountAddresses';
import { createRoute } from '../helpers/creeateRoute';
import { RoutePathNamespace } from '../models/RoutePathNamespace';
import { RouteType } from '../models/RouteType';
import { UserData } from '../../account/pages/UserData/UserData';
import { UserPassword } from '../../account/pages/UserPassword/UserPassword';
import { AccountOrders } from '../../account/pages/AccountOrders/AccountOrders';
import { ResetPasswordPage } from '../../application/pages/ResetPasswordPage/ResetPasswordPage';

const defaultRoutes: RouteType[] = [
  {
    path: RoutePathNamespace.HOME_PAGE,
    element: <StorePage />,
  },
  {
    path: RoutePathNamespace.PRODUCT_PAGE,
    element: <StorePage />,
  },
  {
    path: RoutePathNamespace.CHECKOUT_PAGE,
    element: <BasketPage />,
  },
  {
    path: RoutePathNamespace.CONTACT_PAGE,
    element: <ContactPage />,
  },
  {
    path: RoutePathNamespace.ALL_ROUTES,
    element: <Navigate to="/" replace={true} />,
  },
];

export const authRoutes = createRoute([
  ...defaultRoutes,
  {
    path: RoutePathNamespace.ACCOUNT_PAGE,
    element: <ProfilePage />,
    isChildren: true,
  },
]);

export const noAuthRoutes = createRoute([
  ...defaultRoutes,
  {
    path: RoutePathNamespace.LOGIN_PAGE,
    element: <LoginPage />,
  },
  {
    path: RoutePathNamespace.REGISTER_PAGE,
    element: <RegisterPage />,
  },
  {
    path: RoutePathNamespace.RESET_PASSWORD_PAGE,
    element: <ResetPasswordPage />,
  },
]);

export const globalRoutes = createRoute([
  {
    path: RoutePathNamespace.PAYMENT_PAGE,
    element: <PaymentPage />,
  },
  {
    path: RoutePathNamespace.MENU_PAGE,
    element: <MenuPage />,
  },
  {
    path: RoutePathNamespace.ALL_ROUTES,
    element: <MainPagesRoute />,
  },
]);

export const accountRoutes = createRoute([
  {
    path: AccountRoutePathNamespace.INFO_PAGE,
    element: <UserData />,
  },
  {
    path: AccountRoutePathNamespace.ADDRESS_PAGE,
    element: <AccountAddresses />,
  },
  {
    path: AccountRoutePathNamespace.ORDER_PAGE,
    element: <AccountOrders />,
  },
  {
    path: AccountRoutePathNamespace.PASSWORD_PAGE,
    element: <UserPassword />,
  },
]);
