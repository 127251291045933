import { useEffect, useRef } from 'react';

export const useEffectFirst = (cb: () => void) => {
  const savedCallback = useRef(cb);
  const isFirstRun = useRef<boolean>(false);

  useEffect(() => {
    savedCallback.current = cb;
  }, [cb]);

  useEffect(() => {
    if (!isFirstRun.current) {
      isFirstRun.current = true;
      cb();
    }
  }, [cb]);
};
