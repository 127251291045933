import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ForgotPasswordDialog } from '../../../common/components/ForgotPasswordDialog/ForgotPasswordDialog';
import { SahinButton } from '../../../common/components/SahinButton/SahinButton';
import { SahinTextField } from '../../../common/components/TextField/SahinTextField';
import { useAppDispatch } from '../../../common/context/hooks';
import { generalActions } from '../../../common/context/slice';
import { SahinFetch } from '../../../common/helpers/Fetch';
import { RequestPathNamespace } from '../../../common/models/RequestPathNamespace';
import { yup } from '../../init/yupInit';
import { useStyles } from './LoginPage.styles';
import { createLink } from '../../../common/helpers/creeateRoute';
import { RoutePathNamespace } from '../../../common/models/RoutePathNamespace';

const sahidAuthCodes = ['token', 'userID', 'expire', 'session'];

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

export const LoginPage = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [searchQuery] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<any>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] =
    useState(false);

  useEffect(() => {
    if (sahidAuthCodes.every((item) => searchQuery.get(item))) {
      const authData: any = {};
      sahidAuthCodes.map((item) => (authData[item] = searchQuery.get(item)));
      dispatch(generalActions.setAuthentication(authData));
    }
  });

  const onSubmit = useCallback(
    async (data: any) => {
      await new SahinFetch()
        .post(RequestPathNamespace.LOGIN, data)
        .then((data) => {
          dispatch(generalActions.setAuthentication(data));
          new SahinFetch().get(RequestPathNamespace.USER).then((data) => {
            dispatch(generalActions.setUser(data));
          });
          navigate('library');
        })
        .catch(() => {
          setError('password', {
            type: 'custom',
            message: t('errorMessages.notFoundUser'),
          });
        });
    },
    [dispatch, navigate, setError, t],
  );

  const handleForgotPasswordDialogOpen = () => {
    setForgotPasswordDialogOpen((prev) => !prev);
  };

  return (
    <Grid className={classes.root} component={Paper} xs={11} lg={4} item>
      <Grid
        spacing={2}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        container
      >
        <Grid justifyContent="center" container>
          <Typography fontWeight={600} fontSize={30}>
            {t('login.title')}
          </Typography>
        </Grid>
        <SahinTextField
          name="email"
          label={t('form.mail')}
          error={errors?.email?.message}
          inputProps={register('email')}
          fullWidth
        />
        <SahinTextField
          name="password"
          type="password"
          error={errors?.password?.message}
          label={t('form.password')}
          inputProps={register('password')}
          fullWidth
        />
        <Grid justifyContent="space-between" alignItems="center" item container>
          <Grid xs={6} item>
            <Typography
              sx={{
                color: 'black',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={handleForgotPasswordDialogOpen}
            >
              {t('forgotPassword.title')}
            </Typography>
          </Grid>
          <Grid xs={6} item container>
            <SahinButton type="submit">{t('login.submitButton')}</SahinButton>
          </Grid>
        </Grid>
        <Grid xs={12} justifyContent="center" item container>
          <Button
            variant="text"
            sx={{ marginTop: 3, fontWeight: 900 }}
            to={createLink(
              RoutePathNamespace,
              RoutePathNamespace.REGISTER_PAGE,
            )}
            component={Link}
          >
            Hesabın yok mu? Hemen Kaydol !
          </Button>
        </Grid>
      </Grid>
      <ForgotPasswordDialog
        open={forgotPasswordDialogOpen}
        onClose={handleForgotPasswordDialogOpen}
      />
    </Grid>
  );
};
