export enum MethodType {
  GET = 'GET',
  POST = 'POST',
}

export interface ISahinFetch {
  get: (route: string, data: any) => any;
  post: (route: string, data: any) => any;
  setBaseUrl: (baseUrl: string | undefined) => ISahinFetch;
}
