import {
  Facebook,
  HelpOutline,
  Instagram,
  LinkedIn,
  Phone,
  Pinterest,
  Twitter,
  YouTube,
} from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../context/hooks';
import { phoneCountryFormat, phoneTurkeyFormat } from '../../utils/helper';
import { IconButton } from '../IconButton/IconButton';
import { Logo } from '../Logo/Logo';
import { useStyles } from './Footer.styles';

export const Footer = memo(() => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const brand = useAppSelector((state) => state.general.brand);

  const socialAddress = [
    {
      icon: Instagram,
      href: brand?.socialMedia?.instagram,
      name: 'instagram',
    },
    {
      icon: Facebook,
      href: brand?.socialMedia?.facebook,
      name: 'facebook',
    },
    {
      icon: Twitter,
      href: brand?.socialMedia?.twitter,
      name: 'twitter',
    },
    {
      icon: YouTube,
      href: brand?.socialMedia?.youtube,
      name: 'youtube',
    },
    {
      icon: Pinterest,
      href: brand?.socialMedia?.pinterest,
      name: 'pinterest',
    },
    {
      icon: LinkedIn,
      href: brand?.socialMedia?.linkedin,
      name: 'linkedin',
    },
  ];

  return (
    <Grid justifyContent="center" className={classes.root} container>
      <Grid xs={12} spacing={{ xs: 4 }} item container>
        <Logo justifyContent="center" xs={12} />
        <Grid xs={12} lg={6} alignItems="center" item container>
          <Grid spacing={{ xs: 4 }} container>
            <Grid xs={12} md={6} style={{ gap: 10 }} item container>
              <Grid
                justifyContent="center"
                alignItems="center"
                style={{ gap: 25, whiteSpace: 'nowrap' }}
                container
              >
                <Grid sx={{ alignItems: 'center', display: 'flex' }}>
                  <HelpOutline style={{ fontSize: 45 }} />
                </Grid>
                <Grid>
                  <Typography
                    color="text.secondary"
                    fontWeight={900}
                    fontSize={17}
                    sx={{ textDecoration: 'none' }}
                  >
                    {t('footer.infoText1')}
                  </Typography>
                  <Typography>
                    <small>{t('footer.infoText2')}</small>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {brand?.contact.phone && (
              <Grid xs={12} md={6} style={{ gap: 10 }} item container>
                <Grid
                  justifyContent="center"
                  alignItems="center"
                  style={{ gap: 25 }}
                  container
                >
                  <Grid sx={{ alignItems: 'center', display: 'flex' }}>
                    <Phone style={{ fontSize: 45 }} />
                  </Grid>
                  <Grid>
                    <Typography
                      component={Link}
                      to={`tel:${phoneCountryFormat(brand?.contact.phone)}`}
                      color="text.secondary"
                      fontWeight={900}
                      fontSize={17}
                      sx={{ textDecoration: 'none' }}
                      target="_blank"
                    >
                      {phoneTurkeyFormat(brand?.contact.phone)}
                    </Typography>
                    <Typography>
                      <small>{brand?.contact.email}</small>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Box sx={{ p: { xs: 3, md: 1 } }} />
          <Grid
            xs={12}
            justifyContent="center"
            alignItems="center"
            gap={1}
            container
          >
            <img src="/logo.png" alt="Restorant Kolay" height={15} />{' '}
            <Box sx={{ p: 1 }} />| <Box sx={{ p: 1 }} />
            <small> E-Ticaret paketleri ile hazırlanmıştır.</small>
          </Grid>
        </Grid>

        <Grid xs={12} lg={3} alignItems="center" item container>
          <Grid className={classes.social} justifyContent="center" container>
            {socialAddress.map((item, index) => {
              if (!item.href) return null;
              const Icon = item.icon;
              return (
                <IconButton
                  key={index}
                  onClick={() =>
                    window.open(
                      `https://www.${item.name}.com/${item.href}`,
                      '_blank',
                    )
                  }
                >
                  <Icon />
                </IconButton>
              );
            })}
          </Grid>
          <Box sx={{ pt: 2 }} />
        </Grid>
      </Grid>
    </Grid>
  );
});
