import { Box, CircularProgress, Grid, Typography } from '@mui/material';

interface LoadingProps {
  size?: number;
  gap?: number;
  finallyControl?: boolean;
  finallyText?: string;
}

export const Loading = ({
  size = 30,
  gap = 5,
  finallyControl = false,
  finallyText,
}: LoadingProps) => {
  return (
    <Grid justifyContent="center" alignItems="center" container>
      <Typography fontSize={size}>
        <Box sx={{ padding: gap }}>
          {!finallyControl ? <CircularProgress /> : finallyText}
        </Box>
      </Typography>
    </Grid>
  );
};
