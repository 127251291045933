import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    padding: 50,
    borderRadius: 10,

    [theme.breakpoints.down('md')]: {
      padding: 20,
      marginTop: 80,
    },
  },
}));
