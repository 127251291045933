import { Grid } from '@mui/material';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Loading } from '../../../common/components/Loading/Loading';
import { useAppSelector } from '../../../common/context/hooks';
import { getCdnImageUrl } from '../../../common/helpers/image';

export const BranchSlider = () => {
  const sliders = useAppSelector((state) => state.general.slider);

  const handleSliderClick = (url: string) => () => {
    if (url.length > 0) window.open(url, '_blank');
  };

  if (!sliders && sliders === undefined) return <Loading />;
  if (!sliders) return <></>;
  return (
    <Grid sx={{ borderRadius: 10, overflow: 'hidden' }} container>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {sliders.map((item) => (
          <SwiperSlide key={item.url}>
            <Grid
              component={item.url ? 'button' : 'div'}
              onClick={handleSliderClick(item.url)}
              container
            >
              <img
                src={getCdnImageUrl(item.image)}
                alt="Restorant Kolay"
                style={{
                  width: '100%',
                  maxHeight: 600,
                  cursor: item.url.length > 0 ? 'pointer' : 'default',
                }}
              />
            </Grid>
          </SwiperSlide>
        ))}
      </Swiper>
    </Grid>
  );
};
