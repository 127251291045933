import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    background: theme.palette.background.paper,
    borderBottom: '1px solid #eee',
    boxShadow: 'none',
    padding: '1rem 0',
    color: '#444',
    '& svg': {
      color: 'currentColor',
    },
  },
  link: {
    color: 'currentColor',
    textDecoration: 'none',
  },
}));
