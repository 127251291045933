export const en = {
  common: {
    approve: 'APPROVE',
    cancel: 'CANCEL',
    yes: 'YES',
    no: 'NO',
    other: 'Other',
    send: 'SEND',
    empty: 'Empty',
  },
  header: {
    menu: {
      home: 'HOME',
      menu: 'MENU',
      login: 'LOGIN',
      register: 'REGISTER',
      project: 'PROJECTS',
      store: 'STORE',
      library: 'LIBRARY',
      references: 'REFERENCE',
    },
    goToBasket: 'GO TO BASKET',
  },
  login: {
    title: 'LOGIN',
    submitButton: 'LOGIN',
    withSahId: 'LOGIN WITH SAHİD',
  },
  register: {
    title: 'REGISTER',
    submitButton: 'REGISTER',
  },
  contact: {
    submitButton: 'SUBMIT',
  },
  form: {
    fullName: 'Full Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    mail: 'Mail',
    password: 'Password',
    passwordAgain: 'Password (Again)',
    newPassword: 'New Password',
    company: 'Company',
    phone: 'Phone',
    content: 'Description',
    language: 'Language Options',
    suggestion: 'The ones you like',
    country: 'Country',
    city: 'City',
    county: 'County',
    name: 'Name',
    surname: 'Surname',
    address: 'Address',
  },
  placeholder: {
    firstName: 'First Name',
    lastName: 'Last Name',
    mail: 'Mail',
    password: 'Password',
    passwordAgain: 'Password (Again)',
    newPassword: 'New Password',
    company: 'Company Name (Optional)',
    name: 'Name',
    phone: 'Phone',
    content: 'Can you explain a little bit about the project you want?',
    language: `Turkish ,English`,
    suggestion: 'Examples of sites',
  },
  label: {
    yesHaveManagementPanel: 'Yes, I want a management panel',
    noNotHaveManagementPanel: 'No, I do not want a management panel',
    corporateSite: 'Corporate Site',
    eCommerceSite: 'E-Commerce Site',
    newsPortalSite: 'News Portal Site',
    electionCandidateSite: 'Election Candidate Site',
  },
  errorMessages: {
    passwordEqual: 'Passwords must be equal',
    notFoundUser: 'Not found user',
    notFoundGame: 'Not Found Game',
    notFoundBlog: 'Not Found Blog',
    registerError: 'An error occurred while recording',
    recaptchaError: 'Please verify that you are not a robot',
  },
  language: {
    turkish: 'TÜRKÇE',
    english: 'ENGLISH',
  },
  paymentTypes: {
    HAVALE: 'Transfer',
    ONLINE_POS: 'Online Pos',
    GATE_POS: 'Door With Pos',
    CASH: 'Cash',
    OTHER: 'Other',
  },
  productCard: {
    promotion: 'PROMOTION',
    addToBasket: 'ADD TO BASKET',
    removeToBasket: 'REMOVE TO BASKET',
  },
  footer: {
    infoText1: 'For Your Questions and Suggestions',
    infoText2: 'You can visit the Help Center.',
    securePayment: 'Secure Payment',
  },
  basket: {
    emptyBasket: 'Your basket is empty',
    minPay: 'Minimum Payment Amount {{price}} TL',
  },
  orderStatus: {
    pending: 'Received',
    preparing: 'Preparing',
    onWay: 'On The Way',
    delivered: 'Delivered',
    rejected: 'Canceled',
  },
  forgotPassword: {
    title: 'Forgot Password',
    success: 'Your password has been sent to your e-mail address.',
    error: 'An error occurred while sending your email.',
    resetPassword: 'Reset Password',
  },
};
