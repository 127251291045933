import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { AccountAddresses } from '../../../account/pages/AccountAddresses/AccountAddresses';
import { yup } from '../../../application/init/yupInit';
import { SahinButton } from '../../../common/components/SahinButton/SahinButton';
import { AddressDTO } from '../../../common/context/models';
import { sahinAlertDialog } from '../../../common/components/AlertDialog/AlertDialog';

const paymentAddressFormResolver = yupResolver(
  yup
    .object({
      name: yup.string().min(2).max(30).required(),
      surname: yup.string().min(2).max(30).required(),
      phone: yup.string().length(10).required(),
      address: yup.string().min(10).max(120).required(),
    })
    .required(),
);

export const AddressSelect = ({ paymentData, next }: any) => {
  const [selected, setSelected] = useState<AddressDTO>();

  const onSelectHandler = async (selected: AddressDTO) => {
    const data: any = await paymentAddressFormResolver(selected, {}, {} as any);
    if (Object.keys(data?.errors).length) {
      sahinAlertDialog({
        message: 'Adres bilgilerini düzeltmelisiniz!',
        type: 'error',
      });
      return false;
    }
    paymentData.address = selected;
    setSelected(selected);
  };

  return (
    <>
      <AccountAddresses onSelect={onSelectHandler} selected={selected} />
      <Grid justifyContent="flex-end" sx={{ pt: 3 }} container>
        <Grid xs={8} lg={3} item container>
          <SahinButton disabled={!selected} onClick={next}>
            ÖDEME YÖNTEMİ
          </SahinButton>
        </Grid>
      </Grid>
    </>
  );
};
