import moment from 'moment';
import { Filter } from '../context/models';
import { EnumConvertedDataType } from '../models/EnumDataType';
import { CacheNames } from '../context/slice';

const BACKEND_API_URL = process.env.REACT_APP_API_URL;

export const getCache = (path: CacheNames, defaultValue?: any): any => {
  try {
    const stringJson = localStorage.getItem(path);
    return JSON.parse(stringJson ?? '0') || defaultValue;
  } catch {
    localStorage.removeItem(path);
  }
  return defaultValue;
};

export const setCache = (path: CacheNames, data: any): void => {
  if (!data) {
    localStorage.removeItem(path);
    return;
  }
  localStorage.setItem(path, JSON.stringify(data));
};

export const addUniqueList = <T>(mainList: T[], newList: T[]) => {
  newList.forEach((item) => {
    if (mainList.indexOf(item) === -1) {
      mainList.push(item);
    }
  });
};

export const shuffleArray = <T>(array: T[]) => {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};

export const applyFilter = <T>(data: T[], filter: Filter) => {
  return data.filter((item) => {
    let result = true;
    const filterValue = Object.entries(filter);
    for (let i = 0; i < filterValue.length && result; i++) {
      const [key, value] = filterValue[i];
      result = applyFilterField(getFieldValue(item, key), value);
    }
    return result;
  });
};

const applyFilterField = (data: any, filter: any) => {
  if (!filter) {
    return true;
  }
  if (Array.isArray(data)) {
    if (Array.isArray(filter) && filter?.length > 0) {
      let result = false;
      for (let i = 0; i < filter.length && !result; i++) {
        result = data.length ? data.includes(filter[i]) : true;
      }
      return result;
    }
    return true;
  }
  return data.toLowerCase().indexOf(filter) > -1;
};

export function isNullOrUndefined(value: any): boolean {
  return value === undefined || value === null;
}

export function getFieldValue(data: any, fieldName: string): any {
  if (isNullOrUndefined(data)) {
    return undefined;
  }
  const index = fieldName.indexOf('.');
  if (index > 0) {
    return getFieldValue(
      data[fieldName.substring(0, index)],
      fieldName.substring(index + 1),
    );
  }
  return data[fieldName];
}

export const enumToArray = <T extends Object>(
  enumeration: T,
): EnumConvertedDataType<T>[] => {
  return (Object.keys(enumeration) as Array<keyof T>).map((key) => ({
    key: enumeration[key],
    label: String(key),
  }));
};

export const currencyFormat = (currency: number, precision = 2) => {
  const formatter = new Intl.NumberFormat('tr-TR', {
    // style: 'currency',
    currency: 'TRY',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: precision, // (causes 2500.99 to be printed as $2,501)
    // localeMatcher: "",
    // style: "",
    // currencySign: "",
    // useGrouping: false,
    // minimumIntegerDigits: 0,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    minimumSignificantDigits: 2,
    maximumSignificantDigits: 2,
  });

  return `${formatter.format(currency)} ₺`;
  return `${currency.toFixed(precision)} ₺`;
};

export const fetcher = async (props: any) => {
  const { resource, token } = props;
  let currentResource: RequestInfo | URL = resource;
  if (
    typeof currentResource === 'string' &&
    !currentResource.startsWith('http')
  ) {
    currentResource = `${BACKEND_API_URL}/${currentResource}`;
  }

  let options = undefined;
  if (token) {
    options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }

  const response: any = await fetch(currentResource, options);

  const data = await response.json();
  if (!response?.ok) {
    const error: any = new Error(response?.statusText);
    error.response = response;
    error.data = data;
    error.status = response.status;
    throw error;
  }
  return data?.data;
};

export const createMapping = <T>(data: T[], key: string = 'id') => {
  const result: Map<string | number, T> = new Map([]);
  data.forEach((item) => {
    const fieldKey = getFieldValue(item, key);
    result.set(fieldKey, item);
  });
  return result;
};

export const timeBetweenMinutes = (day: Date) => {
  const now = moment(Date.now());
  const second = moment(day);
  return now.diff(second, 'hours');
};

export const dateFormat = (day: Date) => {
  return moment(day).format('D MMMM, HH:mm');
};

export const phoneCountryFormat = (
  phone?: string,
  isStartWithCountryCode = true,
) => {
  if (!phone) return phone;
  const result = phone.replaceAll(' ', '');
  if (result.length !== 10) {
    console.error(result + ' lenght error!');
  }
  return isStartWithCountryCode ? `90${result}` : result;
};

export const phoneTurkeyFormat = (phone?: string, isStartWithZero = true) => {
  let result = phoneCountryFormat(phone);
  if (!result) return phone;
  result = [
    result.substring(2, 5),
    result.substring(5, 8),
    result.substring(8, 12),
  ].join(' ');

  return isStartWithZero ? `0${result}` : result;
};
