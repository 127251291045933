import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { Blog } from '../../blog/models/Blog';
import { Project } from '../../project/models/Project';
import { Reference } from '../../reference/models/Reference';
import { basketController } from '../helpers/sliceHelper';
import {
  AuthDTO,
  BasketProduct,
  Brand,
  Category,
  Menu,
  Product,
  Slider,
  ThemeMode,
  UserDTO,
} from './models';
import { Nullable } from '../models/Nullable';
import { getCache, setCache } from '../utils/helper';

export enum CacheNames {
  AUTH = 'SESSION',
  BASKET = 'BASKET',
}

type SahinPayload<T> = PayloadAction<T | null | undefined>;

export interface GeneralState {
  theme: ThemeMode;
  brand: Nullable<Brand>;
  auth: Nullable<AuthDTO>;
  user: Nullable<UserDTO>;

  basket: BasketProduct[];
  products?: Product[];
  menu: Nullable<Menu[]>;
  categories?: Category[];
  slider?: Nullable<Slider[]>;

  //old
  blogs?: Blog[];
  references?: Reference[];
  projects?: Project[];
}

const initialState: GeneralState = {
  theme: ThemeMode.LIGHT,
  auth: getCache(CacheNames.AUTH, null),
  user: null,
  basket: getCache(CacheNames.BASKET, []),
  menu: null,
  brand: null,
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setTheme: (state: GeneralState, action: PayloadAction<ThemeMode>) => {
      state.theme = action.payload;
    },
    setBrand: (state: GeneralState, action: PayloadAction<Brand>) => {
      state.brand = action.payload;
    },
    setAuthentication: (
      state: GeneralState,
      action: PayloadAction<AuthDTO>,
    ) => {
      setCache(CacheNames.AUTH, action.payload);
      axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${action.payload.token}`;
      state.auth = action.payload;
    },
    clearAuthentication: (state: GeneralState) => {
      delete axios.defaults.headers.common['Authorization'];
      localStorage.removeItem(CacheNames.AUTH);
      state.user = null;
      state.auth = null;
    },
    setUser: (
      state: GeneralState,
      action: PayloadAction<Nullable<UserDTO>>,
    ) => {
      state.user = action.payload;
    },
    updateBasketCount: (
      state: GeneralState,
      action: PayloadAction<BasketProduct>,
    ) => {
      const isExists = state.basket.find((item) => {
        const { count, product } = action.payload;
        if (item.product.id === product.id) {
          item.count += count;
          return true;
        }
        return false;
      });
      if (!isExists) {
        state.basket.push(action.payload);
      }
      sessionStorage.setItem(CacheNames.BASKET, JSON.stringify(state.basket));
    },
    removeBasket: (
      state: GeneralState,
      action: PayloadAction<BasketProduct>,
    ) => {
      state.basket = state.basket.filter((item) => {
        if (action.payload.product.id !== item.product.id) return true;
        return false;
      });
      sessionStorage.setItem(CacheNames.BASKET, JSON.stringify(state.basket));
    },
    setProducts: (state: GeneralState, action: PayloadAction<Product[]>) => {
      state.products = action.payload;
      if (action.payload?.length && state.basket.length) {
        const basket = basketController(action.payload, state.basket);
        if (basket) {
          state.basket = basket;
          sessionStorage.setItem(
            CacheNames.BASKET,
            JSON.stringify(state.basket),
          );
        }
      }
    },
    setCategories: (state: GeneralState, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },
    setBlogs: (state: GeneralState, action: PayloadAction<Blog[]>) => {
      state.blogs = action.payload;
    },
    setReferences: (
      state: GeneralState,
      action: PayloadAction<Reference[]>,
    ) => {
      state.references = action.payload;
    },
    setProjects: (state: GeneralState, action: PayloadAction<Project[]>) => {
      state.projects = action.payload;
    },
    setMenu: (state: GeneralState, action: PayloadAction<Menu[]>) => {
      state.menu = action.payload;
    },
    setSlider: (state: GeneralState, action: SahinPayload<Slider[]>) => {
      state.slider = action.payload;
    },
    changeBasketCount: (
      state: GeneralState,
      action: PayloadAction<{ id: number; count: number }>,
    ) => {
      const index = state.basket.findIndex(
        (item) => item.product.id === action.payload.id,
      );
      if (index >= 0) {
        state.basket[index].count = action.payload.count;
        sessionStorage.setItem(CacheNames.BASKET, JSON.stringify(state.basket));
      }
    },
    clearBasket: (state: GeneralState) => {
      state.basket = [];
      sessionStorage.removeItem(CacheNames.BASKET);
      state.products = state.products?.map((item) => ({ ...item, count: 0 }));
    },
  },
});

export const generalReducer = generalSlice.reducer;
export const generalActions = generalSlice.actions;
