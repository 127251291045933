import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yup } from '../../../application/init/yupInit';
import { SahinButton } from '../../../common/components/SahinButton/SahinButton';
import { addressFormElements } from './PaymentAddress.types';

const paymentAddressFormResolver = yupResolver(
  yup
    .object({
      name: yup.string().min(2).max(30).required(),
      surname: yup.string().min(2).max(30).required(),
      phone: yup.string().length(12).required(),
      address: yup.string().min(10).max(120).required(),
    })
    .required(),
);

export const AddressForm = ({ paymentData, next }: any) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: paymentAddressFormResolver,
    mode: 'onChange',
  });

  const formElements = useMemo(
    () => addressFormElements(register, errors, t),
    [errors, register, t],
  );

  const onSubmit = (data: any) => {
    paymentData.address = data;
    next();
  };

  return (
    <>
      <Grid
        justifyContent="center"
        columnSpacing={6}
        rowSpacing={2}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        item
        container
      >
        {formElements.map(({ field, element }) => {
          const ElementComponent = element;
          return (
            <Grid key={field.name} xs={6} item>
              <ElementComponent {...field} />
            </Grid>
          );
        })}

        <Grid justifyContent="flex-end" sx={{ pt: 3 }} container>
          <Grid xs={5} item>
            <SahinButton type="submit" fullWidth>
              ÖDEME YÖNTEMİ
            </SahinButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
