import { Grid } from '@mui/material';
import { memo, useMemo, useState } from 'react';
import { CategoryList } from '../../../common/components/CategoryList/CategoryList';
import { ProductList } from '../../../common/components/ProductList/ProductList';
import { useAppSelector } from '../../../common/context/hooks';
import { Category } from '../../../common/context/models';
import { BranchSlider } from '../../components/BranchSlider/BranchSlider';

export const StorePage = memo(() => {
  const products = useAppSelector((state) => state.general.products);
  const categories = useAppSelector((state) => state.general.categories);

  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const filteredProducts = useMemo(
    () =>
      selectedCategory
        ? products?.filter(
            (product) => product.category?.id === selectedCategory?.id,
          )
        : products,
    [products, selectedCategory],
  );

  const changeHandle = (category?: Category) => {
    setSelectedCategory(category);
  };

  return (
    <Grid xs={10} item container>
      <BranchSlider />
      <Grid
        sx={{ paddingTop: '3rem' }}
        justifyContent="space-between"
        container
      >
        <CategoryList categories={categories} onChange={changeHandle} xs={12} />
      </Grid>
      <Grid
        sx={{ paddingTop: '3rem' }}
        justifyContent="space-between"
        container
      >
        <ProductList
          xs={12}
          title={selectedCategory?.name ?? 'Ürünler'}
          products={filteredProducts}
        />
      </Grid>
    </Grid>
  );
});
