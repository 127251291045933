import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    padding: 50,
    borderRadius: 10,

    [theme.breakpoints.up('xs')]: {
      marginTop: 70,
      padding: 20,
    },
  },
}));
