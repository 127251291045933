import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const PaymentAddressCard = ({ address }: any) => {
  const { t } = useTranslation();

  const items = [
    {
      label: t('form.name'),
      content: address?.name,
    },
    {
      label: t('form.surname'),
      content: address?.surname,
    },
    {
      label: t('form.phone'),
      content: address?.phone,
    },
    {
      label: t('form.address'),
      content: address?.address,
    },
  ];

  return (
    <Grid spacing={3} container>
      {items.map((item) => (
        <Grid key={item.label} spacing={3} xs={12} lg={6} item container>
          <Grid xs={5} lg={3} justifyContent="space-between" item container>
            <Typography fontWeight={600}>{item.label}</Typography>
            <Typography fontWeight={600}>:</Typography>
          </Grid>
          <Grid xs={7} lg={7} item>
            <Typography>{item.content}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
