import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  button: {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
    color: alpha(theme.palette.primary.light, 1),
  },
  bigged: {
    width: '100%',
    padding: theme.spacing(1.5, 3),
  },
}));
