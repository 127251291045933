import { Grid } from '@mui/material';
import { useAppSelector } from '../../../common/context/hooks';
import { AddressForm } from './AddressForm';
import { AddressSelect } from './AddressSelect';

export const PaymentAddress = ({ paymentData, next }: any) => {
  const auth = useAppSelector((state) => state.general.auth);

  return (
    <Grid
      xs={12}
      lg={8}
      alignContent="flex-start"
      alignItems="flex-start"
      justifyContent="center"
      item
      container
    >
      {!auth && <AddressForm paymentData={paymentData} next={next} />}
      {auth && <AddressSelect paymentData={paymentData} next={next} />}
    </Grid>
  );
};
