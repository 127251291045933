export interface Product {
  id: number;
  name: string;
  url: string;
  description: string;
  price: string; // number;
  stock: number;
  category: Category;
  images: string[];
  isPopular: boolean;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface Category {
  id: number;
  code: string;
  name: string;
  url: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface BasketProduct {
  product: Product;
  count: number;
}

export interface Menu {
  name: string;
  image: string;
  menu: MenuData[];
}

export interface Slider {
  image: string;
  url: string;
}

export interface MenuData {
  name: string;
  price: number;
  description: string;
  order: number;
}

export enum ThemeMode {
  DARK = 'dark',
  LIGHT = 'light',
}

export interface Filter {
  categories: string[];
  name: string;
}

export interface AuthDTO {
  token: string;
  refreshToken: string;
  userID: string;
}

export interface UserDTO {
  id: string;
  fullName: string;
  email: string;
  phone: string;
}

export interface AddressDTO {
  id?: string;
  title?: string;
  name: string;
  surname: string;
  address: string;
  phone: string;
  enabled?: boolean;
  userId?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface Brand {
  store: {
    logo: string;
    title: string;
  };
  socialMedia: {
    facebook?: string;
    instagram?: string;
    twitter?: string;
    linkedin?: string;
    youtube?: string;
    pinterest?: string;
  };
  contact: {
    phone?: string;
    whatsapp?: string;
    email?: string;
    address?: string;
  };
  paymentMethod: any;
}

export interface Order {
  id: number;
  name: string;
  address: AddressDTO;
  basket: BasketProduct[];
  brandId: string;
  paymentMethod: string;
  price: number;
  status: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
}
