import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { App } from './application/App';
import { store } from './common/context/store';
import { fetcher } from './common/utils/helper';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <SnackbarProvider maxSnack={5}>
        <SWRConfig
          value={{
            fetcher,
            errorRetryInterval: 10000,
            errorRetryCount: 0,
            refreshWhenOffline: false,
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            // shouldRetryOnError: (message) => {
            //   sahinAlertDialog({
            //     message,
            //     type: 'error',
            //     duration: 1000,
            //   });
            //   return true;
            // },
          }}
        >
          <App />
        </SWRConfig>
      </SnackbarProvider>
    </Provider>
  </BrowserRouter>,
  // </React.StrictMode>,
);

// reportWebVitals();
