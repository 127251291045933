import { useEffect } from 'react';
import { useAppDispatch } from '../../../common/context/hooks';
import { generalActions } from '../../../common/context/slice';
import { useFetch } from '../../../common/hooks/useFetchSwr';
import { RequestPathNamespace } from '../../../common/models/RequestPathNamespace';
import { Slider } from '../../../common/context/models';

export const SliderProvider = () => {
  const dispatch = useAppDispatch();
  const { data: slider } = useFetch<Slider[]>(RequestPathNamespace.SLIDER);

  useEffect(() => {
    dispatch(generalActions.setSlider(slider));
  });

  return <></>;
};
