import { createTheme, darken, responsiveFontSizes } from '@mui/material';
import { common, grey, indigo, orange } from '@mui/material/colors';
import { ThemeMode } from '../context/models';
import { sahinScrollbar } from './themeHelper';

const themes: { [key in ThemeMode]: any } = {
  [ThemeMode.LIGHT]: {
    primary: orange,
    secondary: {
      main: orange[300],
    },
    background: {
      // default: alpha(orange[100], 0.5),
      // paper: lightGreen[500],
    },
    text: {
      primary: common.black,
      secondary: grey[600],
    },
    shape: {
      borderRadius: 20,
    },
    success: {
      main: '#1cab81',
    },
  },
  [ThemeMode.DARK]: {
    primary: {
      light: indigo[900],
      dark: indigo[600],
      main: common.black,
    },
    secondary: {
      main: darken(indigo[400], 0.5),
    },
    divider: 'rgba(255, 255, 255, 0.5)',
    background: {
      default: indigo[200],
      paper: indigo[300],
    },
    text: {
      primary: common.black,
      secondary: indigo[400],
    },
    shape: {
      borderRadius: 20,
    },
  },
};

export const getTheme = (mode: ThemeMode) => {
  return responsiveFontSizes(
    createTheme({
      typography: {
        // fontSize: 16,
        fontFamily: ['Jura', '-apple-system', 'BlinkMacSystemFont'].join(','),
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            ':root': {
              '--main-color': '#1e1e1e',
              // '--main-color': '#8BC34A',
              '--secondary-color': '#1cab81',
              '--color1': indigo[900],
              '--color2': indigo[800],
              '--color3': indigo[700],
              '--background': `linear-gradient(180deg, ${indigo[700]} 20%, transparent 100%)`,
              '--gradient':
                'linear-gradient(290deg, #1e1e1e 70%, #5e5e5e 100%)',
              '--gradient-reverse':
                'linear-gradient(110deg, #333 30%, #888 100%)',
              '--gradient-color':
                'linear-gradient(290deg, #8BC34A 70%, #1cab81 100%)',
              '--gradient-color-reverse':
                'linear-gradient(110deg, #8BC34A 30%, #1cab81 100%)',
              '--gradient1':
                'linear-gradient(120deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.02) 50%, var(--color1) 100%)',
              '--gradient2':
                'linear-gradient(220deg, var(--color2) 0%, var(--color1) 40%, var(--color3) 100%)',
              '--gradient3':
                'linear-gradient(90deg, rgba(255, 255, 255, 0.05) 0%, var(--color1) 100%)',
              '--gradient4':
                'linear-gradient(120deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.02) 50%, var(--color1) 100%)',
            },
            html: {},
            body: {
              ...sahinScrollbar(),
            },
            canvas: {
              position: 'absolute',
              top: '-38%',
              right: '-47%',
              zIndex: -1,
              opacity: 0.7,
            },
            '[class*=Mui-disabled]': {},
            '.react-confirm-alert-overlay': {
              background: 'transparent !important',
            },
          },
        },
      },
      palette: {
        mode,
        ...themes[mode],
      },
    }),
  );
};
