import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { SahinAccordion } from '../../../common/components/Accordion/SahinAccordion';
import {
  StyledTableCell,
  StyledTableRow,
} from '../../../common/components/Table/Table';
import { useAppDispatch, useAppSelector } from '../../../common/context/hooks';
import { PaymentAddressCard } from '../Payment/AddressCard';
import { PaymentSuccessProps } from './PaymentSuccess.types';
import { useEffect } from 'react';
import { generalActions } from '../../../common/context/slice';
import { getCdnImageUrl } from '../../../common/helpers/image';

export const PaymentSuccess = ({ paymentData }: PaymentSuccessProps) => {
  const dispatch = useAppDispatch();
  const basket = useAppSelector((state) => state.general.basket);

  useEffect(() => {
    return () => {
      dispatch(generalActions.clearBasket());
    };
  }, [dispatch]);

  return (
    <Grid
      xs={12}
      lg={9}
      alignContent="flex-start"
      alignItems="flex-start"
      justifyContent="center"
      sx={{
        background: '#f1f1f1',
        borderRadius: 10,
      }}
      mt={5}
      item
      container
    >
      <Grid justifyContent="center" sx={{ p: { xs: 3, md: 10 } }} container>
        <Grid justifyContent="center" item container>
          <CheckCircleOutlineIcon sx={{ fontSize: '10rem' }} color="success" />
        </Grid>
        <Grid justifyContent="center" item container>
          <Typography
            fontSize="2rem"
            textAlign="center"
            fontWeight={600}
            sx={{ pt: 4 }}
          >
            SİPARİŞ BAŞARIYLA ALINDI !
          </Typography>
        </Grid>
        <Box sx={{ p: 5 }} />
        <Grid justifyContent="center" item container>
          <SahinAccordion
            title="Teslimat Bilgileri"
            content={<PaymentAddressCard address={paymentData.address} />}
            isExpanded
          />
        </Grid>
        <Box sx={{ p: 2 }} />
        <Grid justifyContent="center" item container>
          <SahinAccordion
            title="Sipariş Özeti"
            content={
              <>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: '100%' }}>
                    <TableHead color="#f1f1f1">
                      <TableRow>
                        <StyledTableCell>ÜRÜN</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>FİYAT </StyledTableCell>
                        <StyledTableCell> ADET</StyledTableCell>
                        <StyledTableCell>TOPLAM</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {basket.map(({ product, count }, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell
                            sx={{ width: '80px' }}
                            component="th"
                            scope="row"
                          >
                            <img
                              src={getCdnImageUrl(product.images[0])}
                              alt={product.name}
                              height="80"
                            />
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: '150px' }}>
                            {product.name}
                          </StyledTableCell>
                          <StyledTableCell>
                            {`${product.price} ₺`}
                          </StyledTableCell>
                          <StyledTableCell>{`x${count}`}</StyledTableCell>
                          <StyledTableCell>
                            {`${(Number(product.price) * count).toFixed(2)} ₺`}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            }
            isExpanded
          />
        </Grid>
        {paymentData?.note && (
          <>
            <Box sx={{ p: 2 }} />
            <Grid justifyContent="center" item container>
              <SahinAccordion
                title="Sipariş Notu"
                content={<Typography>{paymentData.note} </Typography>}
                isExpanded
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};
