import { FC } from 'react';
import { SahinTextField } from '../../../common/components/TextField/SahinTextField';
import { SahinMaskField } from '../../../common/components/MaskField/SahinMaskField';

export interface FormObject {
  xs: number;
  element: FC<any>;
  field: {
    type: string;
    label: string;
    name: string;
    error: string;
    data?: any;
    inputProps: any;
    fullWidth: boolean;
    multiline?: boolean;
    rows?: number;
  };
}

// ad soyad telefon ülke şehir posta kodu not
export const addressFormElements = (
  register: any,
  errors: any,
  translate: any,
): FormObject[] => {
  return [
    {
      xs: 6,
      element: SahinTextField,
      field: {
        type: 'text',
        label: translate('form.firstName'),
        name: 'name',
        error: errors?.name?.message,
        inputProps: register('name'),
        fullWidth: true,
      },
    },
    // {
    //   xs: 6,
    //   element: SahinDropdown,
    //   field: {
    //     type: 'text',
    //     label: translate('form.country'),
    //     name: 'country',
    //     data: counties,
    //     value: data.country,
    //     error: errors?.country?.message,
    //     ...register('country'),
    //     fullWidth: true,
    //   },
    // },
    // {
    //   xs: 6,
    //   element: SahinDropdown,
    //   field: {
    //     type: 'text',
    //     label: translate('form.city'),
    //     name: 'city',
    //     data: cities,
    //     value: data.city,
    //     error: errors?.city?.message,
    //     ...register('city'),
    //     fullWidth: true,
    //   },
    // },
    {
      xs: 6,
      element: SahinTextField,
      field: {
        type: 'text',
        label: translate('form.lastName'),
        name: 'surname',
        error: errors?.surname?.message,
        inputProps: register('surname'),
        fullWidth: true,
      },
    },
    {
      xs: 6,
      element: SahinMaskField,
      field: {
        type: 'text',
        label: translate('form.phone'),
        name: 'phone',
        error: errors?.phone?.message,
        inputProps: register('phone'),
        fullWidth: true,
      },
    },
    {
      xs: 6,
      element: SahinTextField,
      field: {
        type: 'text',
        label: translate('form.address'),
        name: 'address',
        error: errors?.address?.message,
        inputProps: register('address'),
        fullWidth: true,
        multiline: true,
        rows: 3,
      },
    },
    // {
    //   xs: 6,
    //   element: SahinTextField,
    //   field: {
    //     type: 'text',
    //     label: translate('form.county'),
    //     name: 'county',
    //     error: errors?.county?.message,
    //     inputProps: register('county'),
    //     fullWidth: true,
    //   },
    // },
  ];
};

// const counties = ['Türkiye'];
// const cities = [
//   'ADANA',
//   'ADIYAMAN',
//   'AFYONKARAHİSAR',
//   'AĞRI',
//   'AMASYA',
//   'ANKARA',
//   'ANTALYA',
//   'ARTVİN',
//   'AYDIN',
//   'BALIKESİR',
//   'BİLECİK',
//   'BİNGÖL',
//   'BİTLİS',
//   'BOLU',
//   'BURDUR',
//   'BURSA',
//   'ÇANAKKALE',
//   'ÇANKIRI',
//   'ÇORUM',
//   'DENİZLİ',
//   'DİYARBAKIR',
//   'EDİRNE',
//   'ELAZIĞ',
//   'ERZİNCAN',
//   'ERZURUM',
//   'ESKİŞEHİR',
//   'GAZİANTEP',
//   'GİRESUN',
//   'GÜMÜŞHANE',
//   'HAKKARİ',
//   'HATAY',
//   'ISPARTA',
//   'MERSİN',
//   'İSTANBUL',
//   'İZMİR',
//   'KARS',
//   'KASTAMONU',
//   'KAYSERİ',
//   'KIRKLARELİ',
//   'KIRŞEHİR',
//   'KOCAELİ',
//   'KONYA',
//   'KÜTAHYA',
//   'MALATYA',
//   'MANİSA',
//   'KAHRAMANMARAŞ',
//   'MARDİN',
//   'MUĞLA',
//   'MUŞ',
//   'NEVŞEHİR',
//   'NİĞDE',
//   'ORDU',
//   'RİZE',
//   'SAKARYA',
//   'SAMSUN',
//   'SİİRT',
//   'SİNOP',
//   'SİVAS',
//   'TEKİRDAĞ',
//   'TOKAT',
//   'TRABZON',
//   'TUNCELİ',
//   'ŞANLIURFA',
//   'UŞAK',
//   'VAN',
//   'YOZGAT',
//   'ZONGULDAK',
//   'AKSARAY',
//   'BAYBURT',
//   'KARAMAN',
//   'KIRIKKALE',
//   'BATMAN',
//   'ŞIRNAK',
//   'BARTIN',
//   'ARDAHAN',
//   'IĞDIR',
//   'YALOVA',
//   'KARABÜK',
//   'KİLİS',
//   'OSMANİYE',
//   'DÜZCE',
// ];
