export const sahinScrollbar = () => ({
  scrollbarColor: 'rgba(0, 0, 0, 0.6)',
  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    width: 12,
  },
  '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    border: 'yellow',
    cursor: 'pointer',
  },
  '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
    backgroundColor: 'pink',
  },
  '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
    backgroundColor: 'darkred',
  },
  '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
    backgroundColor: 'green',
  },
});
